<template>
    <div v-show="isShow" class="select">
        <ul class="select-label">
            <li class="select-label-text">{{ label }}</li>
            <!-- <li class="f14" v-if="labelSwitch">{{ getLabel(value) }}</li> -->
        </ul>

        <div class="select-body">
<!--
          <el-checkbox-group v-model="checkList">
                <check-item v-for="(item, index) in data" :key="item.id || index" :checkList="checkList"
                    v-bind="item" :class="[direction == 'column' ? 'right-none' : '']"></check-item>
            </el-checkbox-group>-->

          <el-checkbox-group v-model="mValue">
              <el-checkbox :label="item.value" :value="item.value" v-for="(item, index) in data" :key="item.id || index">{{ item.label }}</el-checkbox>
          </el-checkbox-group>



        </div>
    </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import CheckItem from "./CheckItem";

export default {
    name: "SchemaCheckbox",

    components: {
        CheckItem
    },

    mixins: [schemaMixin],

    provide() {
        return {
            mode: this,
        };
    },

    props: {
        value: {
            default: 0,
        },
        labelSwitch: {
            default: true,
        },
        isShow: {
            default: true,
        },
        data: {
            type: Array,
        },
    },

    data() {
        return {
            checkList: [],
        }
    },

    created() {
        this.checkList = this.$attrs.schema.value;
    },

    computed: {
        direction() {
            return this.data.length > 3 ? "column" : "row";
        },
    },
    
    methods: {
        getLabel(value) {
            return this.data.find((v) => v.value == value)?.label || "暂无匹配";
        },
    },
}

</script>

<style lang="scss" scoped>
.select {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #fff;

  .select-label {
    display: flex;
    align-items: center;

    .select-label-text {
      width: 77px;
      color: #969799;
      font-size: 13px;
    }
  }

  .select-body {
    display: flex;
    flex-wrap: wrap;
  }
}

::v-deep .right-none {
    margin-right: 15px;
    &:last-child {
        margin-right: 15px;
    }
}

.mode-select {
  display: flex;
  justify-content: space-between;

  .mode-select-label {
    font-size: 14px;
    /*no*/
    line-height: 32px;
    /*no*/
  }

  .mode-select-body {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>