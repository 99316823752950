<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-26 14:21:13
-->
<template>
  <config-item :label='label'>
    <div style="display:flex;justify-content: flex-end;font-size:14px;margin-top:10px;">
      {{loginType}}</div>
  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import {getDataBy} from "@/api/form";

export default {
  name: "SchemaWord",
  mixins: [schemaMixin],
  data() {
    return {
      loginType: '常规登录方式',
      loginMode:[],
      authType:''
      /*defaultOptions: {
        placeholder: "选择日期时间",
        format: "yyyy-MM-dd HH:mm:ss",
      },*/
    };
  },
  async created() {

    //根据租户获取授权方式
    let data={
      ckey: "auth_type,login_mode"
    }

    //silent 静默登录，ticket常规登录，ticket-silent常规静默登录
   // uname_pword 账户密码登录；phone 手机号登录；wxphone微信一键登录

    let res=await getDataBy(data);
    this.authType=res.data.auth_type.cvalue;
    if(this.authType=='silent'){
        this.loginType='静默登录'
    }
    else if(this.authType=='ticket'){
      this.loginType='常规登录方式'
    }
    else if(this.authType=='ticket-silent'){
      this.loginType='常规静默登录'
    }

   /* if(res.data.login_mode.cvalue){
      this.loginMode=res.data.login_mode.cvalue.split(',');
    }*/

  }


};
</script>