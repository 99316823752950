<template>
  <div :class="{ pt10: padd.includes('top'), pb10: padd.includes('bottom') }" class="flex pl10 pr10 bg-white" v-show="isShow">
    <div class="w70 f13 f-grey pt10">{{ label }}</div>

    <div style="flex: 1;">
      <!-- 选择跳转到的页面 -->
      <div v-if="!mValue.id" :style="'float:' + place" class="flex col-center h32">
        <!-- <el-button type="text">预览页面</el-button> -->
        <div class="f14 jump-button" @click="open">选择跳转到的页面</div>
      </div>

      <!-- 修改 -->
      <div v-else class="flex col-center h32" style="width: 100%;float:right;">
        <el-tag size="small" style="margin-right: 5px;width: calc(74% - 50px);">{{ pageName }}</el-tag>
        <!-- <el-button type="text">预览页面</el-button> -->
        <div class="f14 jump-button" @click="open">修改</div>
      </div>
      <div class="tips">搜索结果页面和更多内容可自定义配置<span style="color: #409eff;cursor: pointer;" @click="toCustom">去自定义</span></div>
    </div>
    <jump-dialog-view ref="jump"></jump-dialog-view>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import { mapGetters } from "vuex";
import router from "@/router";
import JumpDialogView from "@/custom-schema-template/components/SchemaJump/JumpDialog";
export default {
  name: "SchemaSearchJump",
  components: {JumpDialogView},
  mixins: [schemaMixin],

  provide() {
    return {
      value: this.value,
    };
  },

  props: {
    value: {
      default: () => ({}),
    },
    place: {
      default: () => ({}),
    },
    padd: {
      default: "left,right,top,bottom",
    },
    isShow: {
        default: true,
    },
  },

  computed: {
    ...mapGetters(["project", "fixedPages"]),
    pageName() {
      let page = null;
      switch (this.mValue.type) {
        case "insid":
          page = { name: this.mValue?.data?.name };
          break;

        case "applet":
          page = { name: this.mValue.mode == '1' ? '跳转小程序' : '微信半屏小程序' };
          break;

        case "link":
          page = { name: "外链h5" };
          break;
        default:
          break;
      }
      console.log('page',page)
      return page.name;
    },
  },

  methods: {
    open() {
      this.$refs.jump.open();
    },
    toCustom(){
      router.push({ path: "/mall/pages-manage" });
    }
  },
};
</script>

<style lang="scss" scoped>
.jump-button {
  border-radius: 3px;
  background: #409eff;
  color: white;
  padding: 4px 7px;
  text-align: center;
}
.tips{
  float: right;
  margin-top: 5px;
  font-size: 12px;
  color: #999999;
}
</style>
