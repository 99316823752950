/*
 * @Description: 用户api
 * @Autor: WangYuan
 * @Date: 2021-09-22 16:22:01
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-22 16:27:10
 */

import request from "@/utils/request";
import { encryption } from "@/utils/index";
import qs from "qs";
import website from "@/utils/website";

import user from "@/store/user";

import store from "@/store";
import { validateNull } from "@/utils/validate";
import { Message } from "element-ui";

const FORM_CONTENT_TYPE = "application/x-www-form-urlencoded";
const basicAuth = "Basic " + window.btoa(website.formLoginClient);

/**
 * 登录
 */
//export const login = async (data) => request({ url: "/login", method: "POST", data })

export function login(data) {
  const encPassword = encryption(data.password, website.vite_pwd_enc_key);

  const dataP = {
    username: data.username,
    randomStr: data.randomStr,
    code: data.code,
    grant_type: data.grant_type,
    scope: data.scope,
  };
  const dataObj = qs.stringify({
    password: encPassword,
  });

  return request({
    url: "/auth/oauth2/token",
    method: "post",
    params: dataP,
    data: dataObj,
    headers: {
      skipToken: true,
      Authorization: basicAuth,
      "Content-Type": FORM_CONTENT_TYPE,
      "TENANT-ID": user.state.tenantId,
    },
  });
}

/*
export const login = async (data) => request({


/!*    username: admin
    randomStr: blockPuzzle
    code: 6fbf9nSnsTyzTIV61DVTYhFeihv5cAgeRGGqyiKp2Zu6dVlu64Zu6Y8U6O+Huo/RkrM1WlYL9a0XDiEuc4UDV6qEIn6omYjmFa1KqsK3bcw=
        grant_type: password
scope: server*!/


    url: "/auth/oauth2/token?username"+data.username+'&randomStr=blockPuzzle&&code='+data.code+'&scope=server',
    method: "POST", data })*/

// export const login = async (data) => request({ url: "/easyapp/user/login", method: "POST", data })
// export const login = async (data) => request({ url: "/admin/tenant/details", method: "get", data })

/**
 * 注册
 */
export const register = async data => request({ url: "/register", method: "POST", data });

export const refreshTokenApi = refresh_token => {
  // console.log("refresh_token:",refresh_token,store.refreshToken)
  const grant_type = "refresh_token";
  const scope = "server";
  // 获取当前选中的 basic 认证信息
  //const basicAuth = Session.get('basicAuth');

  /* let data={
        grant_type:'refresh_token',
        scope:'server',
        refresh_token:refresh_token
    }*/

  return request({
    url: "/auth/oauth2/token",
    headers: {
      skipToken: true,
      Authorization: basicAuth,
      "Content-Type": FORM_CONTENT_TYPE,
    },
    method: "post",
    params: { refresh_token, grant_type, scope },
  });
};

/**
 * 校验令牌，若有效期小于半小时自动续期
 * @param refreshLock
 */
export const checkToken = (refreshTime, refreshLock) => {

    //console.log("===校验令牌====");

    //const basicAuth = Session.get('basicAuth');

   // console.log("user.state.token:",store.getters.refreshToken);

  request({
    url: "/auth/token/check_token",
    headers: {
      skipToken: true,
      Authorization: basicAuth,
      "Content-Type": FORM_CONTENT_TYPE,
    },
    method: "get",
    params: { token: store.getters.token.split("Bearer ")[1] },
  })
    .then(response => {
      if (validateNull(response) || response.code === 1) {
        clearInterval(refreshTime);
        return;
      }

      const expire = Date.parse(response.data.expiresAt);

      //console.log("====expire===:",expire);

      if (expire) {
        const expiredPeriod = expire - new Date().getTime();

        //小于半小时自动续约
        if (expiredPeriod <= 30 * 60 * 1000) {
          if (!refreshLock) {
            refreshLock = true;
            /*  useUserInfo()
                              .refreshToken()*/
            refreshToken(refreshTime);
            // this.$store.commit('pageTop', this.form)
            //console.log("===refreshToken===：",res);

            /*       this.$store.user.refreshToken()
                            .catch(() => {
                                clearInterval(refreshTime);
                            });*/

            refreshLock = false;
          }
        }
      }
    })
    .catch(() => {
      // 发生异常关闭定时器
      clearInterval(refreshTime);
    });
};

export const refreshToken = refreshTime => {
  //  return new Promise((resolve, reject) => {

  // const refreshToken = Session.get('refresh_token');

  refreshTokenApi(store.getters.refreshToken)
    .then(res => {
      /* user.setAuthToken()
                // 存储token 信息
                state.token = res.access_token
                setAuthToken(res.access_token)
                state.refreshToken = res.refresh_token
                setRefreshToken(res.refresh_token)*/

      console.log("res.access_token:", res.access_token);

      store.commit("setToken", res.access_token); // 提交一个mutation，增加state中的count值10
      store.commit("setRToken", res.refresh_token); // 提交一个mutation，增加state中的count值10

      // store.mapMutations.setToken(res.access_token);
      //store.mapMutations.setRToken(res.refresh_token);

      //  resolve(res);
    })
    .catch(err => {
      //const message = '"演示环境，仅供预览"';

      console.log("====catch====:", err);

      Message({
        message: err.msg,
        type: "error",
      });

      //return Promise.reject(new Error(message))
      // useMessage().error(err.msg);

      // reject(err);
    });

  // });
};

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  return request({
    url: "/admin/user/info",
    method: "get",
  });
};

export const logout = () => {
  return request({
    url: "/auth/token/logout",
    method: "delete",
  });
};

export const EditInfo = data => {
  return request({
    url: "easyapp/config",
    method: "put",
    data
  });
};

// export const register = async (data) => request({ url: "/easyapp/user", method: "POST", data })

//获取验证图片  以及token
// export const reqGet = async (data) => request({ url: "/code/create", method: "get", data })

// //滑动或者点选验证
// export const reqCheck = async (data) => request({ url: "/code/check", method: "POST", data })

// /**
//  * 获取用户信息
//  */
// export const getUserInfo = async () => request({ url: "/admin/user/info", method: "get" })

// /**
//  * 后端控制路由，isRequestRoutes 为 true，则开启后端控制路由
//  * @method getAdminMenu 获取后端动态路由菜单(admin)
//  */
// export function useMenuApi() {
// 	return {
// 		getAdminMenu: (params) => {
// 			return request({
// 				url: '/admin/menu',
// 				method: 'get',
// 				params,
// 			});
// 		},
// 	};
// }
