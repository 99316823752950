import * as CryptoJS from "crypto-js";

// 表单序列化
export const serialize = data => {
  const list = [];
  Object.keys(data).forEach(ele => {
    list.push(`${ele}=${data[ele]}`);
  });
  return list.join("&");
};



/**
 *加密处理
 */

export function encryption(src, keyWord) {

  console.log("====src===:",src,keyWord);

  const key = CryptoJS.enc.Utf8.parse(keyWord);
  // 加密
  var encrypted = CryptoJS.AES.encrypt(src, key, {
    iv: key,
    mode: CryptoJS.mode.CFB,
    padding: CryptoJS.pad.NoPadding,
  });

  console.log("===encrypted.toString()===:",encrypted.toString());

  return encrypted.toString();
}

