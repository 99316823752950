import request from '@/utils/request';

// 获取字典
export const getDictItem = async (type) => request({ url: "admin/dict/type/" + type, method: "get" })

// 文章列表
export const getArticleList = async (params) => request({ url: "service/article/page", method: "get", params })

// 文章分类
export const getAllList = async (params) => request({ url: "service/category/getAllList", method: "get", params })

// 招募分类
export const getCondition = async (params) => request({ url: "form/category/condition", method: "get", params })

// 招募列表
export const getFormPage = async (params) => request({ url: "form/form/page", method: "get", params })


// 分类查询
export const categoryList = async (params) => request({ url: "easyapp/category/list", method: "get", params })

// 页面分页
export const pagesPage = async (params) => request({ url: "easyapp/pages/page", method: "get", params })


