<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-10 14:20:53
-->
<template>
  <config-item :label="label" v-show="isShow">
    <div style="margin-bottom: 5px;" v-for="(item, index) in value" :key="item.id || index" v-bind="item">
      <el-input type="textarea" v-model="item.value" :placeholder="item.options.placeholder" size="small" :maxlength="item.options.max" show-word-limit></el-input>
    </div>
  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
export default {
  name: "SchemaTextarea",
  mixins: [schemaMixin],
  props:{
    data: {
      type:[],
    },
    isShow: {
      default: true,
    },
  },
};
</script>
