<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-10 14:20:53
-->
<template>
  <div>
    <div class="string-tab">
      <div>{{ label }}</div>
      <div>
        <el-checkbox v-model="mValue[1]"></el-checkbox>
        <span style="margin-left: 8px;">{{ mValue[1] ? "显示" : "隐藏" }}</span>
      </div>
    </div>

    <el-input v-model="mValue[0]" v-bind="mOptions" :placeholder='mOptions.placeholder' size="small"
      :maxlength="mOptions.max" show-word-limit></el-input>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
export default {
  name: "SchemaStringTab",
  mixins: [schemaMixin],
};
</script>

<style>
.string-tab {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 8px;
  font-size: 14px;
}
</style>