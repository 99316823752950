/*
 * @Description: 项目配置数据
 * @Autor: WangYuan
 * @Date: 2021-08-31 19:57:35
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-11-02 09:55:11
 */

// 固定页面集合
export const fixedPages = [
  {
    id: 'search',
    name: '搜索页',
    type: 'fixed'
  },
  {
    id: 'category',
    name: '底部第二个界面',
    type: 'fixed'
  },
  {
    id: 'car',
    name: '底部第三个界面',
    type: 'fixed'
  },
  {
    id: 'tabFourth',
    name: '底部第四个界面',
    type: 'fixed'
  },
  {
    id: 'my',
    name: '我的',
    type: 'fixed'
  },

]

// 导航配置 bottomNavigation
export const navigation = {
  label: '导航',
  styles: {
    background: '#fff'
  },
  list: [
    {
      id: '00001',
      icon: 'icon-shop',
      text: '首页',
      jump: {
        // type: 'custom',
        // id: '000000',
        type: 'fixed',
        id: 'home'
      }
    },
    // {
    //   id: '00003',
    //   icon: 'icon-sort',
    //   text: '分类',
    //   jump: {
    //     type: 'fixed',
    //     id: 'category'
    //   }
    // },
    // {
    //   id: '00004',
    //   icon: 'icon-cart',
    //   text: '购物车',
    //   jump: {
    //     type: 'fixed',
    //     id: 'car'
    //   }
    // },
    {
      id: '00005',
      icon: 'icon-my',
      text: '我的',
      jump: {
        type: 'fixed',
        id: 'my'
      }
    }
  ]
}

export const topNavigation = {
  label: '顶部导航',
  styles: {
    text: '',
    seat: 'center',
    color: '#000000',
    background: '#fff'
  },
}

// 商品分组配置
export const goodsGroups = [
  {
    name: '新品上架',
    type: '0001',
    level: 1,
    child: []
  },
  {
    name: '热销排行',
    type: '0002',
    level: 1,
    child: []
  }
]

// 商品列表模板配置
export const listTpl = {
  model: 'one',
  addIcon: 'cart'
}

// 商品列表模板配置
export const categoryTpl = {
  advertising: false // 广告位
}

// 商城初始配置
export const rojectModel = {
  name: '',
  cover:
    'https://i.niupic.com/images/2022/11/02/aaa2.jpeg',
  configEntity: {
    bottomNavigation: JSON.stringify(navigation),
    topNavigation: JSON.stringify(topNavigation),
    goodsGroups: JSON.stringify(goodsGroups),
    listTpl: JSON.stringify(listTpl),
    categoryTpl: JSON.stringify(categoryTpl),
    hotList: JSON.stringify([]),
    backgroundColor: '#FFFFFF'
  },
  pagesEntityList: [
    // 自定义页面集合 home 1首页，2个人中心，0其他
    {
      pagesId: '',
      name: '首页',
      home: 1,
      sign:'home',
      componentEntityList: [],
    }, {
      pagesId: '',
      name: '个人中心',
      home: 2,
      sign:'user',
      componentEntityList: [],
    }
  ]
}
