/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-03-24 16:07:46
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-11 10:14:08
 */
import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import global from '@/config/global'
import website from "@/utils/website";

import {
    Message,
    MessageBox
} from 'element-ui'

// create an axios instance
const request = axios.create({
    baseURL: global.baseApi, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 15000 // request timeout
})

// request interceptor
request.interceptors.request.use(config => {

    console.log("===config===:", config);

    if (store.getters.token && config.url.indexOf('auth/oauth2/token') == -1 && config.url.indexOf('auth/token/check_token') == -1) {
        config.headers['Authorization'] = store.getters.token
    }
    else if (config.url.indexOf('auth/oauth2/token') != -1 || config.url.indexOf('auth/token/check_token') != -1) {
        const basicAuth = 'Basic ' + window.btoa(website.formLoginClient);
        config.headers['Authorization'] = basicAuth;
    }
    if (config.url.indexOf('admin/tenant/list') != -1) {
        config.headers['TENANT-ID'] = '1';
    }
    else {
        config.headers['TENANT-ID'] = store.getters.tenantId;//补充租户id
    }

    console.log("===onfig.params===:", config);

    //需要模拟数据的地方
    if (config.params && config.params.env && config.params.env == 'dev') {
        config.headers['env'] = 'dev';//补充租户id
    }

    return config

}, error => {
    return Promise.reject(error)
})

// response interceptor
/*request.interceptors.response.use(response => {

    const token = response.headers['right-token']
    token && store.commit('setToken', token)
    console.log("====response===:", response);
    return response.data

})*/

// HTTPresponse拦截
request.interceptors.response.use(res => {


    /* const token = res.headers['right-token']
     token && store.commit('setToken', token)*/

    const status = Number(res.status) || 200;
    //console.log("===status===:", status);

    console.log("==status==:", status, res);


    if (status == 423) {
        const message = '"演示环境，仅供预览"';
        Message({
            message: message,
            type: 'error'
        })
        return Promise.reject(new Error(message))
        //return Promise.reject({ msg: '"演示环境，仅供预览"' });
    } else if (status == 424) {
        // const message='"登录状态已过期，请点击重新登录"';
        //console.log("424");
        MessageBox.confirm('登录状态已过期，请点击重新登录', '系统提示', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
        })
            .then(() => {

                /* store.dispatch('LogOut').then(() => {
                   hb_disappear();
                   let userInfoMy = getStore({ name: 'userInfoMy' });
                   let sign = userInfoMy.orgTypeSignNew;
                   let src = '/userLogin/' + sign;
                   router.push({ path: src })
                 })*/

            })
            .catch(() => {
                //let src = '/userLogin/' + sign;
                //router.push({ path: src })

            });

        /*   useMessageBox()
               .confirm('登录状态已过期，请点击重新登录')
               .then(() => {
                 Session.clear(); // 清除浏览器全部临时缓存
                 window.location.href = '/'; // 去登录页
                 return;
               });*/


    } else if (status !== 200) {

        Message({
            message: message,
            type: 'error'
        })
        return Promise.reject(new Error(message))


    }
    else {

        console.log('res.config.url', res.config.url);

        if (res.data.code == 0 || res.config.url == '/auth/oauth2/token' || res.config.url == 'easyapp/pages/checkPull') {
            return res.data;
        }
        else {

            const message = res.data.msg;
            Message({
                message: message,
                type: 'error'
            })

            return Promise.reject(new Error(message))
        }



    }




}, error => {

    // console.log("error.response.status:", error.response.data.msg);
    // 处理 503 网络异常
    if (error.response.status === 503) {

        Message({
            message: error.response.data.msg,
            type: 'error'
        })

    }
    else {

        Message({
            message: error.response.data.msg,
            type: 'error'
        })
        return Promise.reject(new Error(error.response.data.msg))

    }

    //return Promise.reject(new Error(error))


}
)


export default request
