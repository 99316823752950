<!--
 * @Description: 魔方多维布局配置
 * @Autor: WangYuan
 * @Date: 2021-06-03 13:59:00
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-06-27 11:29:14
-->
<template>
  <div>
    <!-- 魔方行数选择 -->
    <config-item v-show="mValue.model == 'custom'" label="魔方行数">
      <el-select v-model="mValue.row" placeholder="请选择" size="small" @change="changeRow">
        <el-option v-for="(item, index) in keyList" :key="index" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </config-item>

    <!-- 魔方布局 -->
    <template>
      <div class="f-red f13 mb15 pl10 pr10">魔方布局</div>
      <div class="f-grey f12 mb15 pl10 pr10">移动鼠标选定布局区域大小</div>
      <div class="pl10 pl10">
        <cap-cube-layout ref="layout" v-model="mValue.list" :model="mValue.model" :row="mValue.row"
          @onCuurIndex="onCuurIndex"></cap-cube-layout>
      </div>
    </template>

    <!-- 魔方模板选择 -->
    <SchemaSelectImage v-model="mValue.model" :data="modelOptions" class="mt15 mb20" label="模板选择" @change="changeModel">
    </SchemaSelectImage>

    <div v-if="activeItem" class="nav ml10 mr10 mb20">
      <div class="nav-item" style="display: flex;align-items: center;">
        <SchemaUpload style="margin-right: 6px;" v-model="activeItem.image" label="" />

        <div>
          <SchemaString v-model="activeItem.label" label="描述"></SchemaString>
          <SchemaJump v-model="activeItem.jump" label="链接" padd="left,right"></SchemaJump>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import CapCubeLayout from "./CapCubeLayout";
import { initialModels, modelOptions } from "./config";

export default {
  name: "SchemaCapCube",

  mixins: [schemaMixin],

  components: {
    CapCubeLayout,
  },

  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
  },

  data() {
    return {
      modelOptions,
      activeItem: null,
      keyList: [
        { label: "4x4", value: 4 },
        { label: "5x5", value: 5 },
        { label: "6x6", value: 6 },
        { label: "7x7", value: 7 },
      ],
    };
  },

  methods: {
    // 切换模型
    changeModel(model) {
      if (model) {
        // 设置模板对应行数
        let target = this.modelOptions.find((m) => m.value == model);
        this.$set(this.mValue, "row", target.row);

        // 重置模板
        this.$refs.layout.reset();

        console.log(model);

        // 设置模板对应初始数据
        if (model == "custom") {
          this.$set(this.mValue, "list", []);
        } else {
          let list = this.mValue.list;
          let lists = this._.cloneDeep(initialModels[model]);
          lists.forEach((item, index) => {
            if (list[index]) {
              item.image = list[index].image;
            }
          });
          this.$set(this.mValue, "list", lists);
        }
      }
    },

    // 切换行数
    changeRow() {
      this.$refs.layout.reset();
    },

    // 切换单元块设置 
    onCuurIndex(item) {
      this.activeItem = null;
      setTimeout(() => {
        this.activeItem = item;
      }, 10);
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .p10 {
  padding: 0px 10px !important;
}

::v-deep .config-item {
  margin-bottom: 1px !important;
  padding: 0px;
}
</style>
