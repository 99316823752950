<!--
 * @Description: 模板选择容器组件
 * @Autor: WangYuan
 * @Date: 2021-05-31 11:54:32
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-10-18 20:03:21
-->
<template>
  <div v-show="isShow" class="select" :class="[direction == 'column' ? 'flex-column' : '']">
    <ul class="select-label" :class="[direction == 'column' ? 'mb15' : '']">
      <li class="select-label-text">{{ label }}</li>
      <li class="f14" v-if="labelSwitch">
        {{ getLabel(value) }}
      </li>
    </ul>

    <div class="select-body">
      <select-item v-for="(item, index) in data" :key="item.id || index" v-bind="item"
        @custom-event="receiveFromChild"></select-item>
    </div>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import SelectItem from "./SelectItem";

export default {
  name: "SchemaSelectImage",

  components: {
    SelectItem,
  },

  mixins: [schemaMixin],

  provide() {
    return {
      mode: this,
    };
  },
  inject: ['mode'],
  props: {
    value: {
      default: 0,
    },
    labelSwitch: {
      default: true,
    },
    isShow: {
      default: true,
    },
    data: {
      type: Array,
    },
  },

  computed: {
    direction() {
      return this.data.length > 3 ? "column" : "row";
    },
  },

  methods: {
    getLabel(value) {
      return this.data.find((v) => v.value == value)?.label || "暂无匹配";
    },
    receiveFromChild(data) {
      data.ctrl?.show?.forEach(ele => {
        this.mode.schema[ele].isShow = true;
      });
      data.ctrl?.hide?.forEach(ele => {
        this.mode.schema[ele].isShow = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  background: #fff;

  .select-label {
    display: flex;
    align-items: center;

    .select-label-text {
      width: 70px;
      color: #969799;
      font-size: 13px;
    }
  }

  .select-body {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
  }
}

.mode-select {
  display: flex;
  justify-content: space-between;

  .mode-select-label {
    font-size: 14px;
    /*no*/
    line-height: 32px;
    /*no*/
  }

  .mode-select-body {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
