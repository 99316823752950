<!--
 * @Description: 11 手机页面预览容器
 * @Autor: WangYuan
 * @Date: 2021-05-25 14:55:42
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-09 20:19:35
-->
<template>
  <div class="ctn" ref="scrollBox" @scroll="handleScroll"
  :style="curPage.backgroundColor?'position: relative;background:'+curPage.backgroundColor:'position: relative;background:#f5f5f5'"
  >
<!--
    {{curPage.backgroundColor}}-->
    <div v-show="head" class="ctn-head" :style="[getStyle('top-view')]">
      <span class="iconfont icon-get_back ctn_text" :style="[getStyle('top-back')]"></span>
      <div :class="[topNavigat.seat != 'center' && topNavigat.seat ? 'leftSide' : 'edit']">
        <div class="headTitle" :style="'color:' + topNavigat.titleColor">
          {{ topNavigat.headName }}
        </div>
      </div>
    </div>

    <div class="ctn-body">
      <slot></slot>
    </div>
    <div class="ctn-height-tag">
      <span>iPhone 8手机高度</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PhoneCtn",
  props: {
    head: {
      type: Boolean,
      default: true,
    },
    backColor: {
      type: String,
      default: '#fff',
    }
  },

  data() {
    return {
      topNavigat: {},
      scrollHeight: 0,
    };
  },
  computed: {
    ...mapGetters(["project","curPage"]),
  },
  watch: {
    "$store.state.globalTop.navigationBar": {
      handler() {
        this.topNavigat = this.$store.state.globalTop;
      },
      deep: true,
    },
    "$store.state.pageTop.navigationBar": {
      handler() {
        if (this.$store.state.pageTop.navigationBar == "1") {
          this.topNavigat = this.$store.state.globalTop;
        } else {
          this.topNavigat = this.$store.state.pageTop;
        }
        console.log("topNavigat", this.topNavigat);
      },
      deep: true,
    }
  },
  created() {
    console.log('this.$store.state.globalTop111111',this.$store.state.globalTop)
    this.topNavigat = this.$store.state.globalTop;
    if (this.$store.state.globalTop.navigationBar == '1') {
      this.topNavigat = this.$store.state.globalTop;
    } else {
      this.topNavigat = this.$store.state.pageTop;
    }

  },
  methods: {
    getStyle(target) {
      if (target == 'top-view') {
        let result = {
          zIndex: 999,
        }
        if (this.topNavigat.topType != 'default' && this.topNavigat.navigationBar == 'custom') {
          result.position = 'fixed';
          if (this.topNavigat.soak == 1 || this.topNavigat.soak == 2 && this.scrollHeight < 70) {
            result.backgroundColor = 'transparent';
          }
        } else {
          result.position = 'relative';
        }

        result.backgroundColor = this.topNavigat.backgroundColor || '#ffffff';

        return result
      }

      if (target == 'top-back') {
        let result = {
          color: this.topNavigat.titleColor,
        }
        if (this.topNavigat.topType != 'default' && this.topNavigat.navigationBar == 'custom' && this.topNavigat.soak) {
          result.background = this.topNavigat.backgroundColor
          result.opacity = '0.5'
        }

        return result
      }
    },

    handleScroll() {
      // 获取滚动盒子
      const scrollBox = this.$refs.scrollBox;
      // 更新滚动高度
      this.scrollHeight = scrollBox.scrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "iconfont";
  /* Project id 4690810 */
  src: url('//at.alicdn.com/t/c/font_4690810_dut1ojbzmz6.woff2?t=1728618619084') format('woff2'),
    url('//at.alicdn.com/t/c/font_4690810_dut1ojbzmz6.woff?t=1728618619084') format('woff'),
    url('//at.alicdn.com/t/c/font_4690810_dut1ojbzmz6.ttf?t=1728618619084') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-get_back:before {
  content: "\e653";
}

.ctn_text {
  position: absolute;
  top: 33px;
  left: 10px;
  padding: 3px;
  border-radius: 50%;
}

.ctn {
  position: relative;
  width: 375px;
  // max-height: 650px;
  // overflow: auto;
  /*no*/
  margin: 70px auto 200px auto;
  /*no*/
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);

  .ctn-head {
    widows: 100%;
    /*no*/
    height: 64px;
    /*no*/
    // background-image: url("./phone-head.png");
    // background-size: cover;
    // border-bottom: 1px solid #f5f5f5;

    .edit {
      position: absolute;
      top: 33px;
      left: 104px;
    }

    .leftSide {
      position: absolute;
      top: 33px;
      left: 45px;

      .headTitle {
        text-align: left;
        font-size: 17px;
      }
    }

    .headTitle {
      width: 160px;
      font-size: 17px;
      line-height: 22px;
      text-align: center;

      overflow: hidden;
      text-overflow: ellipsis; // 溢出显示省略号
      display: -webkit-box; // 弹性盒子
      -webkit-box-orient: vertical; // 上下垂直排列
      -webkit-line-clamp: 1; // 显示行数（数字可调整）
    }
  }

  .ctn-body {
    min-height: 603px;
    // max-height: 603px;

    /*no*/
    background: #fff;
  }

  .ctn-height-tag {
    position: absolute;
    top: 650px;
    /*no*/
    left: -130px;
    /*no*/
    height: 17px;
    /*no*/
    width: 130px;
    /*no*/
    border-bottom: 1px solid #dedede;
    color: #a2a2a2;
    text-align: left;

    span {
      font-size: 12px;
      /*no*/
    }
  }
}
</style>
