/*
 * @Description: 用户数据管理
 * @Autor: WangYuan
 * @Date: 2021-05-19 09:49:33
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-12-15 17:13:13
 */
import {
  setAuthToken,
  getAuthToken,
  setAuthUserInfo,
  getAuthUserInfo,
  getRefreshToken,
  setRefreshToken,
  getTenantId,
  setTenantId
} from '@/utils/auth'

import { refreshTokenApi } from "@/api/user";
import {Message} from "element-ui";

export default {
  state: {
    token: getAuthToken(),
    userInfo: getAuthUserInfo(),
    refreshToken:getRefreshToken(),
    tenantId:getTenantId()
  },
  getters: {
    token: state => state.token,
    userInfo: state => state.userInfo,
    refreshToken: state => state.refreshToken,
    tenantId: state => state.tenantId,
  },
  mutations: {
    setToken (state, token) {
      state.token ='Bearer '+token
      setAuthToken('Bearer '+token)
      //setAuthToken(token)
    },
    setRToken (state, refreshToken) {
      state.refreshToken = refreshToken
      setRefreshToken(refreshToken)
    },
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
      setAuthUserInfo(userInfo)
    },
    setLTenantId (state, tenantId) {
      state.tenantId = tenantId
      setTenantId(tenantId)
    },
    logout (state) {
      state.token = null
      state.userInfo = null
      state.refreshToken =null
      sessionStorage.clear()
      localStorage.clear()
    },
    refreshToken() {
      console.log("refreshTokenrefreshTokenrefreshTokenrefreshTokenrefreshTokenrefreshToken");
      return new Promise((resolve, reject) => {

       // const refreshToken = Session.get('refresh_token');

        refreshTokenApi(state.refreshToken)
            .then((res) => {
              // 存储token 信息

              this.setToken(res.access_token);
              this.setRToken(res.refresh_token);

              //state.token = res.access_token
              //setAuthToken(res.access_token)

             // state.refreshToken = res.refresh_token
             // setRefreshToken(res.refresh_token)

              resolve(res);
            })
            .catch((err) => {
              //const message = '"演示环境，仅供预览"';

              Message({
                message: err.msg,
                type: 'error'
              })

              //return Promise.reject(new Error(message))
             // useMessage().error(err.msg);

              reject(err);

            });


      });
    }
  },
  actions: {},
  modules: {}
}
