<!--
 * @Description: 页面跳转选择组件
 * @Autor: WangYuan
 * @Date: 2021-08-12 11:06:37
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-26 19:38:10
-->

<template>
  <div v-show="isShow" :class="{ pb10: padd.includes('bottom') }" class=" pl10 pr10 bg-white">
    <div class="w70 f13 f-grey pt10">{{ label }}</div>
    <div style="margin-top: 20px;">
      <div v-for="item in assteValue" :key="item.userAssetSign" class="listing">
        <div style="display: flex;justify-content: space-between;">
          <div><i class="el-icon-s-unfold" style="color: #c6c6c8;"></i> {{ item.name }}</div>
          <el-switch v-model="item.value" @change="switchChange"></el-switch>
        </div>
        <!-- 选择跳转到的页面 -->
        <div v-if="!item.id" :style="'float:' + place" class="flex col-center h32">
          <div class="f14 jump-button" @click="open(item)">选择跳转到的页面</div>
        </div>

        <!-- 修改 -->
        <div v-else :style="'float:' + place" class="flex col-center h32" style="width: 100%;">
          <el-tag size="small" style="margin-right: 5px;width: calc(100% - 50px);" v-if="item.type == 'insid'">{{item.data.name}}</el-tag>
          <el-tag size="small" style="margin-right: 5px;width: calc(100% - 50px);" v-else-if="item.type == 'applet'">{{item.mode == '1' ? '跳转小程序' : '微信半屏小程序'}}</el-tag>
          <el-tag size="small" style="margin-right: 5px;width: calc(100% - 50px);" v-else-if="item.type == 'link'">外链h5</el-tag>
          <div class="f14 jump-button" @click="open(item)">修改</div>
        </div>
      </div>
    </div>
    <jump-dialog-view ref="jump"></jump-dialog-view>
  </div>
</template>

<script>
import { getAssetConfig } from "@/api/goods";
import { mapGetters } from "vuex";
import schemaMixin from "@/mixin/schemaMixin";
import JumpDialogView from "@/custom-schema-template/components/SchemaJump/JumpDialog";
export default {
  name: "SchemaListing",
  components: {JumpDialogView},
  mixins: [schemaMixin],
  props: {
    id:{},
    value: {
      default: () => ({}),
    },
    label: {
            type: String,
    },
    place: {
      default: () => ({}),
    },
    padd: {
      default: "left,right,top,bottom",
    },
    isShow: {
      default: true,
    },
  },
    data() {
        return {
            assteValue: [],
            linkvalue:{},
            mValue: undefined,
            itemData:undefined,
            page:undefined
        }
    },
    watch: {
        value: {
            immediate: true,
            deep:true,
            handler(newVal,oldVal) {
                this.mValue = this.value;
            },
        },

    },
    created() {

        this.checkList = this.$attrs.schema.value;
        this.getAssets();
    },
    computed: {
    ...mapGetters(["project", "fixedPages"]),

  },
  methods: {
    open(item) {

      this.$refs.jump.open()
      this.$refs.jump.value = item
    },
    getAssets() {
        getAssetConfig({enableDisable: true}).then(res => {
            this.assteValue = [];
                res.data.forEach(ele => {
                    this.assteValue.push({name: ele.name, userAssetSign: ele.userAssetSign, value: false,type:'link'});
                });
        }).catch(err => {
            reject(err)
        });
    },
    switchChange() {
        this.mValue = this.assteValue

    },
  },
};
</script>

<style lang="scss" scoped>
.listing {
    width: 100%;
    padding: 6px;
    margin-top: 10px;
    border: 1px solid #c6c6c8;
    justify-content: space-between;
}
.jump-button {
  border-radius: 3px;
  background: #409eff;
  color: white;
  padding: 4px 7px;
  text-align: center;
}
</style>
