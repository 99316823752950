<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-06-01 08:57:21
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-26 15:18:52
-->
<template>
  <config-item v-show="isShow" :label='label'>
    <div class="wrap" style="display:flex;align-items: center;margin:7px 0 0 15px;justify-content: right">
      <el-switch v-model="mValue"></el-switch>
    </div>
  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import IsOpenItem from "./IsOpenItem";

export default {
  name: "SchemaIsOpen",

  mixins: [schemaMixin],
  provide() {
    return {
      mode: this,
    };
  },

  inject: ['mode'],

  components: {
    IsOpenItem,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    labelSwitch: {
      default: true,
    },
    isShow: {
      default: true,
    },
    data: {
      type: Array,
    },
  },

  data() {
    return {
      defaultOptions: {
        trueLabel: "显示",
        falseLabel: "隐藏",
      },
    };
  },
  methods: {

    receiveFromChild(data) {

      console.log(data);

      data.ctrl?.show?.forEach(ele => {
        this.mode.schema[ele].isShow = true;
      });

      data.ctrl?.hide?.forEach(ele => {
        this.mode.schema[ele].isShow = false;
      });
    },


  },
};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 25px;
  /*no*/

  .wrap-label {
    font-size: 14px;
    /*no*/
    line-height: 32px;
    /*no*/
  }
}
</style>