<!--
 * @Description: 模板选择容器组件
 * @Autor: WangYuan
 * @Date: 2021-05-31 11:54:32
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-10-18 20:03:21
-->
<template>
  <div v-show="isShow" class="select">
    <ul class="select-label">
      <li class="select-label-text">{{ label }}</li>
    </ul>
    
    <div class="select-body">
      <SelectItemArr :data="data" :value="value" @custom-event="receiveFromChild"></SelectItemArr>
    </div>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import SelectItemArr from "./SelectItemArr";

export default {
  name: "SchemaSelectArr",

  components: {
    SelectItemArr,
  },

  mixins: [schemaMixin],

  provide() {
    return {
      mode: this,
    };
  },

  inject: ['mode'],

  props: {
    value: {
      default: 0,
    },
    labelSwitch: {
      default: true,
    },
    isShow: {
      default: true,
    },
    data: {
      type: Array,
    },
  },

  computed: {
    direction() {
      return this.data.length > 3 ? "column" : "row";
    },
  },

  methods: {
    getLabel(value) {
      return this.data.find((v) => v.value == value)?.label || "暂无匹配";
    },
    receiveFromChild(data) {
      console.log('receiveFromChild',data)
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #fff;

  .select-label {
    display: flex;
    align-items: center;

    .select-label-text {
      width: 90px;
      color: #969799;
      font-size: 13px;
    }
  }

  .select-body {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
  }
}

.mode-select {
  display: flex;
  justify-content: space-between;

  .mode-select-label {
    font-size: 14px;
    /*no*/
    line-height: 32px;
    /*no*/
  }

  .mode-select-body {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
