<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-06-01 08:57:21
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-26 15:18:52
-->
<template>
  <config-item v-show="isShow"style="display:flex;flex-direction: column;">
    <div class="label">{{label}}</div>
    <el-table :data="mValue" style="width: 100%;margin-top: 10px;" row-key="id" header-row-class-name="tableheader">
      <el-table-column label="课程信息" prop="info"></el-table-column>
      <el-table-column label="标题" prop="title">
        <template slot-scope="scope">
          <input v-model="scope.row.title" @blur="autoSave(scope.row)"></input>
        </template>
      </el-table-column>
      <el-table-column label="显示">
        <template #default="scope">
          <el-switch v-model="scope.row.show" active-color="#409efe"> </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="排序">
        <template #default="scope">::</template>
      </el-table-column>
    </el-table>
  </config-item>
</template>

<script>
import Sortable from "@/js/Sortable.js";
import schemaMixin from "@/mixin/schemaMixin";

export default {
  name: "SchemaTable",

  mixins: [schemaMixin],

  props: {
    value: {
      type: [],
    },
    labelSwitch: {
      default: true,
    },
    isShow: {
      default: true,
    },
  },

  data() {
    return {
      show:false,
      tableData:[],
    };
  },
  mounted() {
    this.rowDrop()
  },
  methods: {
    // 行拖拽
    rowDrop() {
      this.isrowDrop = true
      const tbody = document.querySelector('.el-table__body-wrapper tbody');
      const that = this;
      Sortable.create(tbody, {
        draggable:".el-table__row",
        onEnd({ newIndex, oldIndex }) {
          console.log('拖动了行，序号(index)"'+oldIndex+'"拖动到序号(index)"' + newIndex+'"');
          const currentRow = that.mValue.splice(oldIndex, 1)[0];
          that.mValue.splice(newIndex, 0, currentRow);
          that.tableData = that.mValue
        }
        
      })
      
     console.log('that.mValue------',that.mValue)
      
    },
    autoSave(row){
      console.log('autoSave-----',row)
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .tableheader{
  .el-table__cell{
    color: #606266;
    background-color: #f5f7fa!important;
  }
}
input {
  width: 80px;
  color: #606266;
  border: 0;
  font-size: 13px;
  /*no*/
}
.label{
  transform: translateX(6%);
  font-size: 14px;
  color: #969799;
}
</style>
