<template>
  <div v-show="isShow" class="select" :class="[direction == 'column' ? 'flex-column' : '']">
    <ul class="select-label" :class="[direction == 'column' ? 'mb15' : '']">
      <li class="select-label-text">{{ label }}</li>
      <li class="f14" v-if="labelSwitch">{{ getLabel(value) }}</li>
    </ul>
    <div class="select-body">
      <search-item v-for="(item, index) in data" :key="item.id || index" v-bind="item" @custom-event="receiveFromChild"></search-item>
    </div>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import SearchItem from "./SearchItem";

export default {
  name: "SchemaSearch",

  components: {
    SearchItem,
  },

  mixins: [schemaMixin],

  provide() {
    return {
      mode: this,
    };
  },

  inject: ['mode'],

  props: {
    value: {
      default: 0,
    },
    labelSwitch: {
      default: true,
    },
    isShow: {
      default: true,
    },
    data: {
      type: Array,
    },
  },

  computed: {
    direction() {
      return this.data.length > 3 ? "column" : "row";
    },
  },

  methods: {
    getLabel(value) {
      return this.data.find((v) => v.value == value)?.label || "暂无匹配";
    },
    receiveFromChild(data) {
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #fff;

  .select-label {
    display: flex;
    align-items: center;

    .select-label-text {
      width: 90px;
      color: #969799;
      font-size: 13px;
    }
  }

  .select-body {
    display: flex;
    flex-wrap: wrap;
  }
}

.mode-select {
  display: flex;
  justify-content: space-between;

  .mode-select-label {
    font-size: 14px;
    /*no*/
    line-height: 32px;
    /*no*/
  }

  .mode-select-body {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
