<!--
 * @Description: 模板单项组件
 * @Autor: WangYuan
 * @Date: 2021-05-31 11:55:13
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-01-27 19:43:23
-->
<template>
  <el-tooltip class="item" effect="light" :content="label" placement="bottom" :open-delay="200" :disabled="label == ''">
    <!-- <el-checkbox-group v-model="checkList" @change="clickItem">
    </el-checkbox-group> -->
    <el-checkbox :label="value" :value="value">{{ label }}</el-checkbox>
  </el-tooltip>
</template>

<script>
export default {
  name: 'check-item',

  props: [ 'label', 'value', 'icon', 'checkList' ],

  inject: ['mode'],

  watch: {
    checkList: {
      handler(newValue, oldValue) {
        console.log("newValue, oldValue:",newValue, oldValue);
      /*  if (newValue !== oldValue) {
          this.clickItem();
        }*/

      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    clickItem() {
     /* this.mode.mValue = this.checkList;
      this.mode.mTooltip = this.label*/
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variable";

.mode-select-item {
  padding: 8px 12px;
  min-width: 55px;
  border: 1px solid #dcdee0;
  /*no*/
  border-radius: 0;
  border-color: #ebedf0;
  font-size: 12px;
  /*no*/
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: $color-theme;
    border-color: $color-theme;
    background-color: #e0edff;

    i {
      color: $color-theme;
    }
  }

  i {
    color: #979797;
  }
}

.mode-select-item-active {
  color: $color-theme;
  border-color: $color-theme;
  background-color: #e0edff;

  i {
    color: $color-theme;
  }
}
</style>
