<template>
  <div v-show="isShow" style="padding: 0 15px 0 28px;">
    <div style="display: flex;justify-content:space-between;align-items: center;">
      <span class="hotlist">{{label}}</span>
      <div style="display: flex;align-items: center;position: relative;padding:0 10px;">
        <el-button v-if="hotOpen" class="margin-right:15px" type="text" @click="toHotView">预览热词搜索页面</el-button>
        <el-switch v-model="hotOpen" active-color="#409eff" inactive-color="#eeeeee" @change="switchChange"> </el-switch>
      </div>
    </div>
    <div v-if="hotOpen">
      <div class="nav">
        <draggable v-model="mValue">
          <!-- 编译模式,插槽可供自定义拖拽组件入容器 -->
          <slot v-if="edit"></slot>

          <!-- 非编译模式，根据schema子类遍历数组单项组件 -->
          <template v-else>
            <div style="border: 1px solid #e2e4e8;">
              <el-table :data="mValue" style="width: 100%" header-row-class-name="tableheader" @cell-mouse-down="handleCellDown">
                <el-table-column prop="label" label="关键词">
                  <div class="item" slot-scope="scope">
                    <div v-if="scope.row.labelshow" style="height: 25px;">
                      <el-input style="height: 25px;" :maxlength="10" class="elinput" v-model="scope.row.label" placeholder="请输入" @change="labelChange(scope)" @blur="labelChange(scope)"></el-input>
                    </div>
                    <div v-else style="display: flex;align-items: center;">
                      <img style="width: 15px;height: 15px;" src="../../../assets/image/hot.png" />
                      <span>{{scope.row.label}}</span>
                    </div>
                  </div>
                </el-table-column>
                <el-table-column prop="name" label="类型">
                  <div class="item" slot-scope="scope">
                    <div v-if="scope.row.typeshow" style="height: 25px;">
                      <el-input
                        style="height: 25px;"
                        :maxlength="10"
                        class="elinput"
                        v-model="scope.row.typeName"
                        placeholder="请输入"
                        @change="typeChange(scope)"
                        @blur="typeChange(scope)"></el-input>
                    </div>
                    <span v-else>{{scope.row.typeName}}</span>
                  </div>
                </el-table-column>
                <el-table-column label="操作">
                  <div class="item" slot-scope="scope">
                    <el-button type="text" size="small" @click="handleCellDown(scope)">编辑</el-button>
                    <el-button type="text" size="small" @click="delItem(scope.row.id)">删除</el-button>
                  </div>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </draggable>
        <div>
          <div class="addItem" @click="addItem"><i class="el-icon-plus ml5"></i><span>添加热词</span></div>
          <div class="mb10 f-grey f12 mt5">{{ mOptions.note }}</div>
        </div>
      </div>
    </div>
    <el-dialog title="" class="notheader" width="157px" :visible.sync="hotviewshow" :modal="false" @close="() =>hotviewshow == false">
      <img style="width: 120px;height: 200px;" src="../../../assets/image/hotview.png" />
    </el-dialog>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import typeToComponent from "@/config/schema-template";

export default {
  name: "SchemaHotWordList",

  mixins: [schemaMixin],

  props: {
    label: {
      type: String,
      default: "",
    },
    edit: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object,
      default: () => { },
    },
    isShow: {
        default: true,
    },
  },

  data() {
    return {
      typeToComponent,
      hotOpen:false,
      hotviewshow:false,
    };
  },
  watch:{
    "hotOpen":{
      handler(newValue, oldValue) {
        if(this.mValue){
          this.mValue.forEach(item =>{
            item.isShow = newValue
          })
        }

      },
      deep: true,
      immediate: true,
    }
  },
  mounted() {
    if(this.mValue){
      this.hotOpen = true
      this.mValue.forEach(item =>{
        item.labelshow = false
        item.typeshow = false
        item.isShow = this.hotOpen
      })
    }else{
      this.mValue = []
    }


  },
  methods: {
    handleCellDown(scope){
      let index = scope.$index
      this.mValue[index].labelshow = true
      this.mValue[index].typeshow = true
    },
    //输入框失去焦点
    labelChange(scope){
      let index = scope.$index
      this.mValue[index].labelshow = false
    },
    //输入框失去焦点
    typeChange(scope){
      let index = scope.$index
      this.mValue[index].typeshow = false
    },
    addItem() {
      console.log("...mall-cook-platform");
      console.log(this.mValue, this.mOptions);
      if (this.mValue.length >= this.mOptions?.limit) {
        this.$notify({
          title: "无法新增",
          message: `最多只能添加${this.mOptions?.limit}条数据`,
          type: "warning",
        });
        return;
      }

      this.mValue.push({
        id: this.$getRandomCode(6),
        labelshow:false,
        typeshow:false,
        isShow:this.hotOpen
      });
    },

    delItem(id) {
      let i = this.mValue.findIndex((item) => item.id == id);
      this.mValue.splice(i, 1);
    },
    toHotView(){
      this.hotviewshow = true
    },
    switchChange() {
        this.mValue.switch = this.hotOpen
        console.log('switchChange',this.mValue)
    },


  },
};
</script>

<style lang="scss" scoped>
::v-deep.notheader{
  .el-dialog__header {
    display: none!important;
  }

}
::v-deep.el-dialog__wrapper{
  top: 30%;
  left:70%;
}
::v-deep .config-item {
  margin-bottom: 10px !important;
}
::v-deep .elinput{
  .el-input__inner{
    height: 25px;
  }
}
::v-deep .tableheader{
  .el-table__cell{
    color: #606266;
    background-color: #f5f7fa!important;
  }
}
.hotlist{
  font-size: 13px;
  color: #969799;
}
.item{
  .item__input{
  display: none;
  width: 80px;
  /* 调整elementUI中样式 如果不需要调整请忽略 */
  .el-input__inner{
    height: 24px!important;
  }
  /* 调整elementUI中样式 如果不需要调整请忽略 */
  .el-input__suffix{
    i{
      font-size: 12px !important;
      line-height: 26px !important;
    }
  }
}
.item__txt{
  box-sizing: border-box;
  line-height: 24px;
  padding: 0 9px;
}
}

.hotview{
  position: absolute;
  top: 52%;
  padding: 0 20px;
}
.ml5{
  margin: 0 5px;
}
.mt5{
  margin-top: 5px;
}
.addItem{
  margin-top: 5px;
  width: 95px;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #e2e4e8;
  background: #fff;
  cursor: pointer;
}
</style>
