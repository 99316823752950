<template>
  <config-item :label="label">
    <el-radio style="margin-top: 10px;" v-model="radio" label="example1">默认模版</el-radio>
    <div class="container">
      <span class="example">示例图</span>
      <img src="../../../assets/image/example.jpg" />
    </div>
  </config-item>
</template>
<script>
import schemaMixin from "@/mixin/schemaMixin";
export default {
  name: "SchemaExample",
  mixins: [schemaMixin],
  data() {
    return {
      radio:'example1'
    }
  },
};
</script>
<style lang="scss" scoped>
.container{
  margin-top: 15px;
  padding: 30px 10px 10px 10px;
  width: 100%;
  position: relative;
  background: #eeeeee;
  border-radius: 8px;
  .example{
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 12px;
    color: #fff;
    background: #c4c4c4;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
</style>
