<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-06-03 15:05:00
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-10-18 15:20:57
-->
<template>
  <div class="flex p10 bg-white">
    <div class="w80 f13 f-grey " style="padding-top: 12px;" v-if="label">{{label}}</div>
    <div class="flex-1">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfigItem",
  props: ["label", "info"],
  data() {
    return {};
  },
};
</script>
