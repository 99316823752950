<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-14 14:16:44
-->
<template>
  <config-wrap :title="label">

    <!--标签数据源-->
    <div class="select">

      <ul class="select-label">
        <li class="select-label-text">标签数据源</li>
      </ul>

      <div class="select-body">

        <el-select v-model="mValue.tagSource" size="small" @change="changeCategory">
          <el-option
              v-for="item in tagList"
              :label="item.name"
              :value="item.url+'?tag='+item.tag"
          />
        </el-select>

      </div>

    </div>
    <!--是否吸顶-->

    <!--标签分组吸顶-->
    <div class="select">

      <ul class="select-label">
        <li class="select-label-text">标签分组吸顶</li>
      </ul>

      <div class="select-body">

        <div class="mode-select-item flex-center" v-for="(item,index) in doTopModelList"
             :class="[mValue.doTopModel == item.value ? 'mode-select-item-active' : '']"
             @click="clickItem(item.value,'doTopModel')">
          <span>{{ item.label }}</span>
        </div>


      </div>

    </div>

    <!--显示全部分组-->
    <div class="select">

      <ul class="select-label">
        <li class="select-label-text">显示全部分组</li>
      </ul>

      <div class="select-body">

        <div class="mode-select-item flex-center" v-for="(item,index) in groupModelList"
             :class="[mValue.groupModel == item.value ? 'mode-select-item-active' : '']"
             @click="clickItem(item.value,'groupModel')">
          <span>{{ item.label }}</span>
        </div>

      </div>

    </div>

    <!--显示全部分组-->
    <div class="select">

      <ul class="select-label">
        <li class="select-label-text">标签分组设置</li>
      </ul>

      <div class="select-body ">
        <el-radio v-model="mValue.tagGroupSet" @input="changeTagGroupSet"
                  :label="item.value"
                  v-for="(item,index) in tagGroupSetList">{{ item.label }}
        </el-radio>

      </div>

    </div>


    <div class="mt20 addGroup" @click="btnShowGroup" v-show="mValue.tagGroupSet=='hand'">
      添加分组
    </div>

    <div class="mt20 addGroup mb20" @click="btnAddGroup" v-show="mValue.tagGroupSet=='selfSet'">
      添加自定义分组
    </div>

    <div v-show="mValue.tagGroupSet=='selfSet'">

      <div v-for="(item,index) in mValue.selfCategory"
           :key="index"
           class="self mb20">

        <div class="self-con mb10">
           <div class="mt10 mr10">分组名称:</div>
           <div style="flex:1">
             <el-input type="text" placeholder="请输入标签名" v-model="item.name" size="small"></el-input>
           </div>
        </div>

        <div class="self-con mb10">
          <div class="mt10 mr10" style="font-size:12px;">关联组件:</div>
          <div style="flex:1">
            <!--@change="chooseCom"
             @change="chooseCom(index,$event)"
            -->

<!--         <el-select  placeholder="请选择"
                     size="small" @change="chooseCom"
                     v-model="item.components"
                        >
              <el-option-group
                  v-for="group in project.groupList"
                  :key="group.componentGroupId"
                  :label="group.name">

                <el-option
                    v-for="item in group.componentList"
                    :key="item.component.component"
                    :label="item.component.name"
                    :disabled="item.component.disabled?true:false"
                    :value="item.component.component">
                </el-option>

              </el-option-group>

            </el-select>-->

             <el-select v-model="item.components" placeholder="请选择">
              <el-option
                  v-for="(item,index) in curPage.componentEntityList"
                  :key="item.component"
                  :label="item.name"
                  v-if="item.component!='McTagNav'&&item.component!='navTop'"
                  :disabled="isCanChoose(item.component,index)"
                  :value="item.component"
              >
              </el-option>
            </el-select>


<!--           <el-select v-model="item.components" placeholder="请选择" @change="chooseCom">
              <el-option
                  v-for="item in cities"
                  :key="item.component.component"
                  :label="item.component.component"
                  :value="item.component.component"
                  :disabled="item.component.disabled?true:false"
              >
                <span style="float: left">{{ item.component.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.component.component}}</span>
              </el-option>
            </el-select>-->

          </div>

        </div>


      </div>

<!--    <el-select v-model="value" placeholder="请选择">
        <el-option-group
            v-for="group in options"
            :key="group.label"
            :label="group.label">
          <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-option-group>
      </el-select>-->

    </div>

    <!--手动分组设置-->
    <div class="groupSet mt20" v-if="mValue.tagGroupSet=='hand'">
      <div class="zidong">

        <el-tag class="mr10 mb10"
                v-for="(item,index) in mValue.categoryList"
                :closable="item[showName]!='全部'?true:false"
                @close="handleClose(item)"
        >
          {{ item[showName] }}
        </el-tag>


      </div>
    </div>


    <el-dialog
        title="信息分组"
        :visible.sync="show"
        width="30%"
    >

      <div class="mt30">
        <el-table
            :data="categoryList"
            :header-cell-style="{background:'#f7f8fa',color:'#323233',fontWeight:500}"
            style="width: 100%" :header-cell-class-name="cellClass"
        >

          <el-table-column type="selection" align="center">
            <template #default="scope">
              <div class="chooseTeacher">
                <input type="checkBox" @click="select(scope.row)"
                       :checked="checkObjs.includes(scope.row[clickId])"/>
              </div>
            </template>
          </el-table-column>

          <el-table-column
              :prop="showName"
              label="分组名"
              align='center'
              header-align='center'
          >
          </el-table-column>

          <el-table-column
              prop="createTime"
              label="创建时间"
              align='center'
              header-align='center'
          >
          </el-table-column>


        </el-table>


        <div
            slot="footer"
            class="dialog-footer mt20" style="text-align: center"
        >
          <el-button
              type="primary"
              @click="submit"
          >确 定
          </el-button>
        </div>


      </div>

    </el-dialog>


  </config-wrap>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import typeToComponent from "@/config/schema-template";
import {comDSList, getRuleList, getCategoryList} from "../../api/easyApp";
import {mapGetters, mapMutations} from "vuex";
import { editProject, pageSave, shopUpdate } from "@/api/project";
import globalMethods from "@/utils/globalMethods";

export default {
  name: "SchemaTagGroup",
  mixins: [schemaMixin],
  props: {
    label: {
      type: String,
      default: "",
    },
    edit: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object,
      default: () => {
      },
    },
    tagCategory: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default: {},
    }
  },
  inject: ["control"],
  data() {
    return {
      typeToComponent,
      tagList: [],
      curTagInfo: {},
      doTopModelList: [{
        "label": "吸顶",
        "value": true
      }, {
        "label": "不吸顶",
        "value": false
      }],
      groupModelList: [{
        "label": "显示",
        "value": true
      }, {
        "label": "不显示",
        "value": false
      }],
      tagGroupSetList: [{
        "label": "自动",
        //"value": true,
        "value": 'auto',
      }, {
        "label": "手动",
       // "value": false,
        "value": 'hand',
      }, {
        "label": "自定义",
        "value": 'selfSet'
      }

      ],
      curTagList: [],
      ruleList: [],
      showName: '',
      clickId: '',
      curTag: '',
      categoryList: [],//分类列表
      show: false,
      checkObjs: [],
      selfCategory:[],
      cities: [],
    };
  },
  computed: {
    ...mapGetters(["project", "curPage"])
  },
  async created() {
    console.log("this.curPage:",this.curPage);
    //return;
    this.cities=[];
    console.log("this.project.groupList:",this.project.groupList);
 /*   for(var i in this.project.groupList){
      for(var j in this.project.groupList[i].componentList){
        this.cities.push(this.project.groupList[i].componentList[j]);
      }
    }*/

    //console.log("list:",this.cities);

    let data = {
      tags: this.tagCategory
    }

    let res = await comDSList(data);
    this.tagList = res.data;

    let result = await getRuleList(['courseCategoryList', 'articleCategoryList', 'categoryList']);
    this.ruleList = result.data;

    if (this.mValue.tagSource&&this.mValue.tagGroupSet!='selfSet') {
      //  console.log("this.mValue.tagSourcecacscascsadcccccccccccccccccccccccccccc");
      this.changeCategory(this.mValue.tagSource, 'true');
    }


  },
  methods: {
    ...mapMutations(["setProject",'setCurPage']),
    //选择组件 选择的时候替他拉一个组件过来
    chooseCom(e){
//

 /*     let index = this.control.curPage.componentEntityList.findIndex(
          (item) => item.id == id
      );
      this.control.curPage.componentEntityList.splice(index, 1);
      this.control.curWidget = undefined;
      return;
      this.mValue.selfCategory[index]['components']=e;
      this.$forceUpdate();
      console.log("index:",index,e);

      //curPage.componentEntityList
      console.log("this.curTag.componentEntityList:",this.curPage.componentEntityList);
      //globalMethods*/

    for(var i in this.project.groupList){
        for(var j in this.project.groupList[i].componentList){

          if(this.project.groupList[i].componentList[j].component.component==e){

            let newCom={
              ...this.project.groupList[i].componentList[j].component,
              id:this.getRandomCodeNew(6)
            }
            console.log("newCom:",newCom);
           this.control.curPage.componentEntityList.push(newCom);
          }
        }
      }
      this.$forceUpdate();
      return;

     // console.log("==index==:",index);
      console.log("==e==:",e);
      //e['isReturn']=false;
      this.control.dragWidget = this.$getNewComponent(
          e
      );

      //this.control.dragstatus = true;
      console.log("this.control.dragWidget:",this.control.dragWidget);
      //this.control.dragstatus = true;
      //this.control.dragWidget['isReturn']=false;
      let obj={
        ...this.control.dragWidget,
        'isReturn':false
      }
      this.control.h5Iframe.contentWindow.postMessage(
          {
            even: "drop",
            params: obj
          },
          "*"
      );

      return;

      //this.control.dragstatus = false;
      this.$forceUpdate();
      console.log("this.curPage:",this.curPage);
      //this.setCurPage(this.curPage);
     console.log("this.m");
    // this.btnSave();
     // this.control.dragstatus = false;

    },
    getRandomCodeNew (num = 8, stamp = true) {
      let data = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z'
      ]
      let nums = ''
      let timestamp = parseInt(new Date().getTime() / 1000) + ''
      for (let i = 0; i < num; i++) {
        let r = parseInt(Math.random() * 61)
        nums += data[r]
      }

      return stamp ? timestamp + nums : nums
    },
    btnSave(){
      this.project.pagesEntityList.forEach(ele => {
        if (ele.pagesId == this.curPage.pagesId) ele.componentEntityList = this.curPage.componentEntityList;
      });

        shopUpdate(this.curPage).then(res =>{
          if (res.code == 0) {

            this.setProject(this.project);

          }
        }).catch((err) => {
          this.$message.error(err.msg);
        });

    },
    cellClass(row) {
      if (row.columnIndex === 0) {
        return 'disabledCheck';
      }
    },
    select(row) {

      const index = this.mValue.categoryList.findIndex(item => item[this.clickId] == row[this.clickId]);

      if (index != -1) {
        const index1 = this.checkObjs.findIndex(item => item == row[this.clickId]);

        this.checkObjs.splice(index1, 1);

        this.mValue.categoryList.splice(index, 1);
      } else {
        this.checkObjs.push(row[this.clickId]);
        this.mValue.categoryList.push(row);

      }

      // console.log("checkObjs:",this.checkObjs);

    },
    //切换分类
    async changeCategory(val, isStart) {

      let data = {
        url: val
      }
      let res = await getCategoryList(data);

      if (isStart != 'true') {
        this.mValue.categoryList = [];
        this.checkObjs = [];
      }

      this.categoryList = res.data;

      let tag = val.split('tag=');
      let index = this.ruleList.findIndex((item) => item.ruleId == tag[1]);

      if (index != -1) {
        let info = JSON.parse(this.ruleList[index].ruleData);
        this.showName = info[1].sign;
        this.clickId = info[0].sign;
      }

      if (this.mValue.groupModel == true && this.tagList[0][this.showName] != '全部' && isStart != 'true') {
        let obj = {}
        obj[this.showName] = '全部';
        obj[this.clickId] = '123456';
        this.mValue.categoryList.unshift(obj)
      }


      if (isStart == 'true' && this.mValue.tagGroupSet == 'hand' && this.mValue.categoryList.length > 0) {
        for (var i in this.mValue.categoryList) {
          if (this.mValue.categoryList[i][this.clickId] != '123456') {
            this.checkObjs.push(this.mValue.categoryList[i][this.clickId]);
          }
        }
      }

    },
    btnClick(item) {
      this.curTag = item[this.clickId];
    },
    //确定
    submit() {
      this.show = false;
    },
    //弹出分组框
    btnShowGroup() {
      this.show = true;
    },
    //添加分组
    btnAddGroup(){

      console.log("this.mValue:",this.mValue);

      let obj={
        name:'',
        components:''
      }
      this.mValue.selfCategory.push(obj);

    },
    //删除分组
    handleClose(row) {

      const index = this.mValue.categoryList.findIndex(item => item[this.clickId] == row[this.clickId]);
      if (index != -1) {
        const index1 = this.checkObjs.findIndex(item => item == row[this.clickId]);
        this.checkObjs.splice(index1, 1);
        this.mValue.categoryList.splice(index, 1);
      }

    },
    //
    isCanChoose(value,index){
        let isShow=false;
        const index1 = this.mValue.selfCategory.findIndex((item) => item.components == value);

        if(index1!=-1&&index1!=index){
          isShow=true
        }
       // console.log("value:",value,"index1:",index1);
       // console.log("isShow:",isShow);
        return isShow;

    },
    changeTagGroupSet(e) {

      if (!e) {

        if (this.mValue.groupModel == true && ((this.mValue.categoryList.length > 0 && this.mValue.categoryList[0][this.showName] != '全部') || this.mValue.categoryList.length == 0)) {
          let obj = {}
          obj[this.showName] = '全部';
          obj[this.clickId] = '123456';
          this.mValue.categoryList.unshift(obj)
        } else if (this.mValue.groupModel == false && (this.mValue.categoryList.length > 0 && this.mValue.categoryList[0][this.showName] == '全部')) {
          this.mValue.categoryList.splice(0, 1)
        }

        this.$forceUpdate();

        // console.log("this.mValue.categoryList:", this.mValue.categoryList);

      }

    },
    clickItem(value, cate) {

      if (cate == 'doTopModel') {
        this.mValue.doTopModel = value
      } else {
        this.mValue.groupModel = value

        if (this.mValue.tagGroupSet=='hand') {
          if (this.mValue.groupModel == true && ((this.mValue.categoryList.length > 0 && this.mValue.categoryList[0][this.showName] != '全部') || this.mValue.categoryList.length == 0)) {
            let obj = {}
            obj[this.showName] = '全部';
            obj[this.clickId] = '123456';
            this.mValue.categoryList.unshift(obj)
          } else if (this.mValue.groupModel == false && (this.mValue.categoryList.length > 0 && this.mValue.categoryList[0][this.showName] == '全部')) {
            this.mValue.categoryList.splice(0, 1)
          }
        }

      }

    }


  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variable";

::v-deep .p10 {
  padding: 0px 10px !important;
}

::v-deep .config-item {
  margin-bottom: 1px !important;
  padding: 0px;
}

.select {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #fff;

  .select-label {
    display: flex;
    align-items: center;

    .select-label-text {
      width: 90px;
      color: #969799;
      font-size: 13px;
    }
  }

  .select-body {
    display: flex;
    flex-wrap: wrap;
  }

}


.mode-select-item {
  padding: 8px 12px;
  min-width: 55px;
  border: 1px solid #dcdee0;
  /*no*/
  border-radius: 0;
  font-size: 12px;
  /*no*/
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: $color-theme;
    border-color: $color-theme;
    background-color: #e0edff;

    i {
      color: $color-theme;
    }
  }

  i {
    color: #979797;
  }
}

.mode-select-item-active {
  color: $color-theme;
  border-color: $color-theme;
  background-color: #e0edff;

  i {
    color: $color-theme;
  }
}

.zidong-con {
  border: 2px dashed #cccccc;
  padding: 10px 0px;
  border-radius: 5px;
  color: #666;
  font-size: 14px;
}

.zidong-head {
  height: 35px;
  line-height: 35px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.border {
  border-bottom: 1px dashed #cccccc;
}

.zidong-head-right {
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center
}

.addGroup {
  padding: 15px;
  border: 2px dashed #409fee;
  border-radius: 4px;
  text-align: center;
  color: #409fee;
}

/*// 深度选择器 去掉全选按钮*/
::v-deep .el-table .disabledCheck .cell .el-checkbox__inner {
  display: none;
}

::v-deep .el-table .disabledCheck .cell::before {
  content: '';
  text-align: center;
  line-height: 37px;
}

.self{
  padding:15px;border:1px solid #ededed;border-radius:5px;
}
.self-con{
  display: flex;
  align-items: center;
  font-size:12px;
  color:#969799;
}
</style>
