<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-10 14:20:53
-->
<template>

  <config-item :label='label'>

    <div v-if="icon" class="iconInput">
      <i :class="'icon ' + icon"></i>
      <div class="iconInput-con">
        <el-input v-model="mValue" v-bind="mOptions" :placeholder='mOptions.placeholder' size='mini'
          :maxlength="mOptions.max" show-word-limit></el-input>
      </div>
    </div>

    <div v-else>
      <el-input v-model="mValue" :maxlength="mOptions.max" show-word-limit v-bind="mOptions"
        :placeholder='mOptions.placeholder' size='mini'></el-input>
    </div>

  </config-item>

</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
export default {
  name: "SchemaString",
  mixins: [schemaMixin],
};
</script>

<style lang="scss">
.iconInput {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ededed;

  .iconfont {
    color: #999;
    font-size: 20px;
  }

  .iconInput-con {
    flex: 1;
  }

  .el-input__inner {
    border: none;
  }
}
</style>
