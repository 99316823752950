<template>
    <div>

        <div class="select">
            <ul class="select-label"><li class="select-label-text">分组设置</li></ul>
            <div class="select-body">
            <el-radio-group v-model="groupData" @change="radioChange">
                <el-radio label="automatic">自动分组</el-radio>
                <el-radio label="manual">手动分组</el-radio>
            </el-radio-group>
            </div>
        </div>
       
        <div v-show="groupData == 'manual'" class="add-cate" @click="add">+ 添加分组</div>
            
        <div v-for="item in categoryList" :key="item.categoryId" class="">
            <el-collapse v-model="activeColl" accordion style="margin: 13px">
                <el-collapse-item class="collapse-item">
                    <template slot="title">
                        <i class="el-icon-s-unfold" style="color: #c6c6c8;"></i> {{ item.name }}
                    </template>
                    <div>分组信息</div>
                    <div class="mt6">显示信息：
                        <el-input v-model="item.currentNum" placeholder="请输入内容" size="small" style="width: 60px;"></el-input>个
                    </div>
                    <div class="mt6">分组别名：
                        <el-input v-model="item.categoryName" placeholder="请输入内容" size="small" style="width: 120px;"></el-input>
                    </div>
                </el-collapse-item>

            </el-collapse>
        </div>

        <el-dialog title="招募信息分组" :visible.sync="dialogVisible" width="50%">
            <div class="flex" style="justify-content: space-between;">
                <el-form :model="queryForm" ref="queryRef" :inline="true" @keyup.enter="getList">
    
                    <el-row shadow="hover" class="ml10">
                        <el-form-item label="分组名称" prop="name" size="small">
                            <el-input placeholder="请输入分组名称" v-model="queryForm.name" />
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="handleRefresh" class="ml10" size="small" icon="search" type="success">查询</el-button>
                            <el-button @click="resetQuery" icon="Refresh" size="small">重置</el-button>
                        </el-form-item>
                    </el-row>
                </el-form>
                <el-row>
                    <el-tooltip class="item" effect="dark" content="刷新" placement="top">
                        <el-button circle icon="el-icon-refresh-right" @click="handleRefresh()" />
                    </el-tooltip>
                </el-row>
            </div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @select="handleSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="name" label="分组名称"></el-table-column>
                <el-table-column prop="createTime" label="创建时间"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import { getCategory, getCateQuery } from "@/api/goods";

export default {
    name: "SchemaCategory",

    components: {},

    mixins: [schemaMixin],

    provide() {
        return {
            mode: this,
        };
    },

    props: {
        value: {
            default: 0,
        },
        labelSwitch: {
            default: true,
        },
        isShow: {
            default: true,
        },
        data: {
            type: Array,
        },
    },

    data() {
        return {
            groupData: 'automatic',
            activeColl: '1',
            checkList: [],
            categoryList: [],
            tableData: [],
            checkObj: [],
            dialogVisible: false,
            queryForm: {
                name: '',
            }
        }
    },

    created() {
        this.checkList = this.$attrs.schema.value;
        this.getSort();
    },

    computed: {
        direction() {
            return this.data.length > 3 ? "column" : "row";
        },
    },
    watch: {
        categoryList: {
            handler() {
            },
            deep: true,
        }
    },
    
    methods: {
        getLabel(value) {
            return this.data.find((v) => v.value == value)?.label || "暂无匹配";
        },
        getSort() {
            getCategory({categoryPid: null}).then(res => {
                this.tableData = res.data;
                this.categoryList = [];
                if (this.mValue.length > 0) {
                    this.categoryList = this.mValue;
                } else if (this.groupData == 'automatic') {
                    res.data.forEach(ele => {
                        this.categoryList.push({...ele, currentNum: 5, categoryName: ''})
                    });
                    this.mValue = this.categoryList;
                }
            }).catch(err => {
                reject(err)
            });
        },
        add(){
            this.dialogVisible = true;
            this.$nextTick(() => {})
                this.checkObj.forEach(ele => {
                //  this.tableData.find(row => row.categoryId == ele.categoryId)
                    this.$refs.multipleTable.toggleRowSelection(ele);
                });
              
        },
        radioChange() {
            this.getSort();
        },
        handleSelectionChange(val, selected) {
            this.checkObj = val;
        },
        submit() {
            this.categoryList = [];
            this.dialogVisible = false;
            this.checkObj.forEach(ele => {
                this.categoryList.push({...ele, currentNum: 5, categoryName: ''})
            });
            this.mValue = this.categoryList;
        },
        handleRefresh() {
            getCategory(this.queryForm).then(res => {
                this.tableData = res.data;
            }).catch(err => {
                reject(err)
            });
        },
        resetQuery() {
            this.queryForm.name = '';
            this.handleRefresh();
        }
    },
}

</script>

<style lang="scss" scoped>
.select {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #fff;

  .select-label {
    display: flex;
    align-items: center;

    .select-label-text {
      width: 77px;
      color: #969799;
      font-size: 13px;
    }
  }

  .select-body {
    display: flex;
    flex-wrap: wrap;
  }
}

::v-deep .right-none {
    margin-right: 15px;
    &:last-child {
        margin-right: 15px;
    }
}

.mode-select {
  display: flex;
  justify-content: space-between;

  .mode-select-label {
    font-size: 14px;
    /*no*/
    line-height: 32px;
    /*no*/
  }

  .mode-select-body {
    display: flex;
    flex-wrap: wrap;
  }
}

.add-cate {
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: #3894ff;
  margin: 10px 10px 15px;
  border: 1px dashed #3894ff;
  cursor: pointer;
}

.group-c {
  // width: 100%;
  padding: 6px;
  margin: 13px;
  border: 1px dashed #c6c6c8;
  justify-content: space-between;
}

.el-collapse {
  border: none;
}
::v-deep .el-collapse-item__header {
  border-bottom: none;
}
.collapse-item {
  padding-left: 6px;
  margin-top: 10px;
  border: 1px dashed #c6c6c8;
  justify-content: space-between;
}
</style>