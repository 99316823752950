import request from '@/utils/request'

//获取场景列表
export const getSceneList = async (params) => request({url: "/form/scene/page", method: "get", params})



//获取租户的登录方式 授权方式 form/config/getDataBy
export const getDataBy = async (data) => request({url: "/form/config/getDataBy", method: "POST", data})

