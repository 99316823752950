<!--
 * @Description: 模板单项组件
 * @Autor: WangYuan
 * @Date: 2021-05-31 11:55:13
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-01-27 19:43:23
-->
<template>
  <div class="mode-select-item flex-center">
    <el-select v-model="selectvalue" placeholder="请选择" size="small" @change="clickItem">
      <el-option v-for="item in data" :key="item.id" :label="item.label" :value="item.value"> </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'selectitemarr',

  props: ['label', 'value', 'icon', 'range','data'],

  inject: ['mode'],
  data() {
    return {
      optionlabel:'请选择',
      selectvalue:''
    }
  },
  created() {
    this.selectvalue = this.value
  },
  methods: {
    clickItem() {
      this.mode.mValue = this.selectvalue
      this.$emit('custom-event', this);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variable";

.mode-select-item {
  position: relative;
  padding: 8px 12px;
  min-width: 55px;
  border: 1px solid #dcdee0;
  /*no*/
  border-radius: 0;
  border-color: #ebedf0;
  font-size: 12px;
  /*no*/
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  span{
    padding: 8px 12px;
    min-width: 30px;
    position: absolute;
    background: #fff;
    left: 10%;
    z-index: 10;
  }
}

.mode-select-item-active {
  color: $color-theme;
  border-color: $color-theme;
  background-color: #e0edff;

  i {
    color: $color-theme;
  }
}
</style>
