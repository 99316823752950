<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-06-01 08:57:21
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-26 15:18:52
-->
<template>
  <config-item v-show="isSupport" :label='label'>
    <div>
<!--      curWidget:{{control.curWidget.component}}-->
      <div>
        <el-select style="width: 100%"
                   v-model="mValue.decoratorId"
                   size="small"
                   @change="switchPageStyle">
          <el-option
              v-for="item in tagList"
              :label="item.name"
              :value="item.decoratorId"
          />
        </el-select>

      </div>


      <!--      {{this.pageStyle=JSON.parse(newValue.split('?=')[1]);}}-->
      <div class="dContent">
        <div style="margin-right:10px;" class="f13 f-grey">页面布局</div>
        <div style="flex: 1">
          <el-button @click="btnShowGroup"  size="small">页面样式设置</el-button>
        </div>

      </div>
    </div>


    <el-dialog
        title="样式设置"
        :visible.sync="show"
        width="950px"
        custom-class="right-aligned-dialog"
    >

      <div class="mt30">
        <el-table
            :data="pageStyle"
            border
            :header-cell-style="{background:'#f7f8fa',color:'#323233',fontWeight:500}"
            style="width: 100%"
        >
          <el-table-column
              prop="left"
              label="左边"
              align='center'
              header-align='center'
          >
            <template #default="scope">

              <div v-if="scope.row.left.length>0">

                <el-tabs type="border-card" v-if="scope.row.left.length>1">
                  <el-tab-pane :label="'第'+(index+1)+'个'"
                               v-for="(item,index) in scope.row.left">

                    <div style="border:1px solid #ededed;padding:10px;border-radius:4px;margin-bottom:10px;">


                    <div style="display: flex;align-items: center">
                      <div class="mr10">
                        设置内容:
                      </div>

                      <div :style="item.style">
                     <span v-if="item['type']&&item['type']=='icon'"
                           :class="'icon ' +item.value.substring(2,item.value.length-2)">
                     </span>
                        <span v-else>
                          {{ item.value }}
                      </span>

                      </div>

                    </div>

                    <div v-if="item['type']&&item['type']=='image'">
                      <div>图片宽</div>
                      <div>图片高</div>
                      <div>
                        <div>图片圆角：</div>
                        <el-slider v-model="item['style']['border-radius']" >
                        </el-slider>
                      </div>
                    </div>
                    <div v-else-if="item['type']&&(item['type']=='text'||item['type']=='priceF'||item['type']=='priceY')">
                      <div style="display: flex;align-items: center">
                        <div>文字大小：</div>
                        <div>
                          <el-radio v-model="item['style']['font-size']"  :label="'12px'" style="margin-right:3px;">12px</el-radio>
                          <el-radio v-model="item['style']['font-size']"  :label="'14px'" style="margin-right:3px;">14px</el-radio>
                          <el-radio v-model="item['style']['font-size']"  :label="'16px'" style="margin-right:3px;">16px</el-radio>
                        </div>
                      </div>

                      <div style="display: flex;align-items: center">
                        <div>是否加粗：</div>

                        <div>
                          <el-radio v-model="item['style']['font-weight']" :label="'normal'">否</el-radio>
                          <el-radio v-model="item['style']['font-weight']" :label="'bold'">是</el-radio>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>文字颜色：</div>

                        <div>
                          <el-color-picker v-model="item['style']['color']"></el-color-picker>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="item['type']&&item['type']=='icon'">
                      <div style="display: flex;align-items: center">
                        <div>图标大小：</div>
                        <div>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'15px'">15px</el-radio>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'20px'">20px</el-radio>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'25px'">25px</el-radio>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>图标颜色：</div>
                        <div>
                          <el-color-picker v-model="item['style']['color']"></el-color-picker>
                        </div>
                      </div>
                    </div>

                      <div style="display: flex;align-items: center">
                        <div>上间距：</div>
                        <div>
                          <el-slider v-model="item['style']['margin-top']"  >
                          </el-slider>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>下间距：</div>
                        <div>
                          <el-slider v-model="item['style']['margin-bottom']"  >
                          </el-slider>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>左间距：</div>
                        <div>
                          <el-slider v-model="item['style']['margin-left']"  >
                          </el-slider>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>右间距：</div>
                        <div>
                          <el-slider v-model="item['style']['margin-right']"  >
                          </el-slider>
                        </div>
                      </div>



                    </div>

                  </el-tab-pane>
<!--                  <el-tab-pane label="配置管理">配置管理</el-tab-pane>
                  <el-tab-pane label="角色管理">角色管理</el-tab-pane>
                  <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane>-->
                </el-tabs>



               <div v-else>
                 <div v-for="(item,index) in scope.row.left"
                      style="border:1px solid #ededed;padding:10px;border-radius:4px;margin-bottom:10px;">

                   <div style="display: flex;align-items: center">
                     <div class="mr10">
                       设置内容:
                     </div>

                     <div :style="item.style">
                     <span v-if="item['type']&&item['type']=='icon'"
                           :class="'icon ' +item.value.substring(2,item.value.length-2)">
                     </span>
                       <span v-else>
                          {{ item.value }}
                      </span>

                     </div>

                   </div>

                   <div v-if="item['type']&&item['type']=='image'">
                     <div>图片宽</div>
                     <div>图片高</div>
                     <div>
                       <div>图片圆角：</div>
                       <el-slider v-model="item['style']['border-radius']" >
                       </el-slider>
                     </div>
                   </div>
                   <div v-else-if="item['type']&&(item['type']=='text'||item['type']=='priceF'||item['type']=='priceY')">
                     <div style="display: flex;align-items: center">
                       <div>文字大小：</div>
                       <div>
                         <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'12px'">12px</el-radio>
                         <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'14px'">14px</el-radio>
                         <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'16px'">16px</el-radio>
                       </div>
                     </div>

                     <div style="display: flex;align-items: center">
                       <div>是否加粗：</div>

                       <div>
                         <el-radio v-model="item['style']['font-weight']" :label="'normal'">否</el-radio>
                         <el-radio v-model="item['style']['font-weight']" :label="'bold'">是</el-radio>
                       </div>
                     </div>
                     <div style="display: flex;align-items: center">
                       <div>文字颜色：</div>

                       <div>
                         <el-color-picker v-model="item['style']['color']"></el-color-picker>
                       </div>
                     </div>
                   </div>
                   <div v-else-if="item['type']&&item['type']=='icon'">
                     <div style="display: flex;align-items: center">
                       <div>图标大小：</div>
                       <div>
                         <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'15px'">15px</el-radio>
                         <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'20px'">20px</el-radio>
                         <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'25px'">25px</el-radio>
                       </div>
                     </div>
                     <div style="display: flex;align-items: center">
                       <div>图标颜色：</div>
                       <div>
                         <el-color-picker v-model="item['style']['color']"></el-color-picker>
                       </div>
                     </div>
                   </div>

                   <div style="display: flex;align-items: center">
                     <div>上间距：</div>
                     <div>
                       <el-slider v-model="item['style']['margin-top']"  >
                       </el-slider>
                     </div>
                   </div>
                   <div style="display: flex;align-items: center">
                     <div>下间距：</div>
                     <div>
                       <el-slider v-model="item['style']['margin-bottom']"  >
                       </el-slider>
                     </div>
                   </div>
                   <div style="display: flex;align-items: center">
                     <div>左间距：</div>
                     <div>
                       <el-slider v-model="item['style']['margin-left']"  >
                       </el-slider>
                     </div>
                   </div>
                   <div style="display: flex;align-items: center">
                     <div>右间距：</div>
                     <div>
                       <el-slider v-model="item['style']['margin-right']"  >
                       </el-slider>
                     </div>
                   </div>

                 </div>
               </div>



              </div>

              <div v-else>
                --
              </div>

            </template>


          </el-table-column>

          <el-table-column
              prop="center"
              label="中间"
              align='center'
              header-align='center'
          >
            <template #default="scope">
              <div v-if="scope.row.center.length>0">
                <el-tabs type="border-card" v-if="scope.row.center.length>1">
                  <el-tab-pane :label="'第'+(index+1)+'个'"
                               v-for="(item,index) in scope.row.center">

                    <div style="border:1px solid #ededed;padding:10px;border-radius:4px;margin-bottom:10px;">


                      <div style="display: flex;align-items: center">
                        <div class="mr10">
                          设置内容:
                        </div>

                        <div :style="item.style">
                     <span v-if="item['type']&&item['type']=='icon'"
                           :class="'icon ' +item.value.substring(2,item.value.length-2)">
                     </span>
                          <span v-else>
                          {{ item.value }}
                      </span>

                        </div>

                      </div>

                      <div v-if="item['type']&&item['type']=='image'">
                        <div>图片宽</div>
                        <div>图片高</div>
                        <div>
                          <div>图片圆角：</div>
                          <el-slider v-model="item['style']['border-radius']" >
                          </el-slider>
                        </div>
                      </div>
                      <div v-else-if="item['type']&&(item['type']=='text'||item['type']=='priceF'||item['type']=='priceY')">
                        <div style="display: flex;align-items: center">
                          <div>文字大小：</div>
                          <div>
                            <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'12px'">12px</el-radio>
                            <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'14px'">14px</el-radio>
                            <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'16px'">16px</el-radio>
                          </div>
                        </div>

                        <div style="display: flex;align-items: center">
                          <div>是否加粗：</div>

                          <div>
                            <el-radio v-model="item['style']['font-weight']" :label="'normal'">否</el-radio>
                            <el-radio v-model="item['style']['font-weight']" :label="'bold'">是</el-radio>
                          </div>
                        </div>
                        <div style="display: flex;align-items: center">
                          <div>文字颜色：</div>

                          <div>
                            <el-color-picker v-model="item['style']['color']"></el-color-picker>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="item['type']&&item['type']=='icon'">
                        <div style="display: flex;align-items: center">
                          <div>图标大小：</div>
                          <div>
                            <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'15px'">15px</el-radio>
                            <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'20px'">20px</el-radio>
                            <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'25px'">25px</el-radio>
                          </div>
                        </div>
                        <div style="display: flex;align-items: center">
                          <div>图标颜色：</div>
                          <div>
                            <el-color-picker v-model="item['style']['color']"></el-color-picker>
                          </div>
                        </div>
                      </div>

                      <div style="display: flex;align-items: center">
                        <div>上间距：</div>
                        <div>
                          <el-slider v-model="item['style']['margin-top']"  >
                          </el-slider>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>下间距：</div>
                        <div>
                          <el-slider v-model="item['style']['margin-bottom']"  >
                          </el-slider>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>左间距：</div>
                        <div>
                          <el-slider v-model="item['style']['margin-left']"  >
                          </el-slider>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>右间距：</div>
                        <div>
                          <el-slider v-model="item['style']['margin-right']"  >
                          </el-slider>
                        </div>
                      </div>




                    </div>

                  </el-tab-pane>
                  <!--                  <el-tab-pane label="配置管理">配置管理</el-tab-pane>
                                    <el-tab-pane label="角色管理">角色管理</el-tab-pane>
                                    <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane>-->
                </el-tabs>
                <div v-else>
                  <div v-for="(item,index) in scope.row.center"
                       style="border:1px solid #ededed;padding:10px;border-radius:4px;margin-bottom:10px;">

                    <div style="display: flex;align-items: center">
                      <div class="mr10">
                        设置内容:
                      </div>

                      <div :style="item.style">
                     <span v-if="item['type']&&item['type']=='icon'"
                           :class="'icon ' +item.value.substring(2,item.value.length-2)">
                     </span>
                        <span v-else>
                          {{ item.value }}
                      </span>

                      </div>

                    </div>

                    <div v-if="item['type']&&item['type']=='image'">
                      <div>图片宽</div>
                      <div>图片高</div>
                      <div>
                        <div>图片圆角：</div>
                        <el-slider v-model="item['style']['border-radius']" >
                        </el-slider>
                      </div>
                    </div>
                    <div v-else-if="item['type']&&(item['type']=='text'||item['type']=='priceF'||item['type']=='priceY')">
                      <div style="display: flex;align-items: center">
                        <div>文字大小：</div>
                        <div>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'12px'">12px</el-radio>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'14px'">14px</el-radio>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'16px'">16px</el-radio>
                        </div>
                      </div>

                      <div style="display: flex;align-items: center">
                        <div>是否加粗：</div>

                        <div>
                          <el-radio v-model="item['style']['font-weight']" :label="'normal'">否</el-radio>
                          <el-radio v-model="item['style']['font-weight']" :label="'bold'">是</el-radio>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>文字颜色：</div>

                        <div>
                          <el-color-picker v-model="item['style']['color']"></el-color-picker>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="item['type']&&item['type']=='icon'">
                      <div style="display: flex;align-items: center">
                        <div>图标大小：</div>
                        <div>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'15px'">15px</el-radio>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'20px'">20px</el-radio>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'25px'">25px</el-radio>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>图标颜色：</div>
                        <div>
                          <el-color-picker v-model="item['style']['color']"></el-color-picker>
                        </div>
                      </div>
                    </div>


                    <div style="display: flex;align-items: center">
                      <div>上间距：</div>
                      <div>
                        <el-slider v-model="item['style']['margin-top']"  >
                        </el-slider>
                      </div>
                    </div>
                    <div style="display: flex;align-items: center">
                      <div>下间距：</div>
                      <div>
                        <el-slider v-model="item['style']['margin-bottom']"  >
                        </el-slider>
                      </div>
                    </div>
                    <div style="display: flex;align-items: center">
                      <div>左间距：</div>
                      <div>
                        <el-slider v-model="item['style']['margin-left']"  >
                        </el-slider>
                      </div>
                    </div>
                    <div style="display: flex;align-items: center">
                      <div>右间距：</div>
                      <div>
                        <el-slider v-model="item['style']['margin-right']"  >
                        </el-slider>
                      </div>
                    </div>

                  </div>
                </div>





              </div>
              <div v-else>
                --
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="right"
              label="右边"
              align='center'
              header-align='center'
          >
            <template #default="scope">
              <div v-if="scope.row.right.length>0">
                <el-tabs type="border-card" v-if="scope.row.right.length>1">
                  <el-tab-pane :label="'第'+(index+1)+'个'"
                               v-for="(item,index) in scope.row.right">

                    <div style="border:1px solid #ededed;padding:10px;border-radius:4px;margin-bottom:10px;">


                      <div style="display: flex;align-items: center">
                        <div class="mr10">
                          设置内容:
                        </div>

                        <div :style="item.style">
                     <span v-if="item['type']&&item['type']=='icon'"
                           :class="'icon ' +item.value.substring(2,item.value.length-2)">
                     </span>
                          <span v-else>
                          {{ item.value }}
                      </span>

                        </div>

                      </div>

                      <div v-if="item['type']&&item['type']=='image'">
                        <div>图片宽</div>
                        <div>图片高</div>
                        <div>
                          <div>图片圆角：</div>
                          <el-slider v-model="item['style']['border-radius']" >
                          </el-slider>
                        </div>
                      </div>
                      <div v-else-if="item['type']&&(item['type']=='text'||item['type']=='priceF'||item['type']=='priceY')">
                        <div style="display: flex;align-items: center">
                          <div>文字大小：</div>
                          <div>
                            <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'12px'">12px</el-radio>
                            <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'14px'">14px</el-radio>
                            <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'16px'">16px</el-radio>
                          </div>
                        </div>

                        <div style="display: flex;align-items: center">
                          <div>是否加粗：</div>

                          <div>
                            <el-radio v-model="item['style']['font-weight']" :label="'normal'">否</el-radio>
                            <el-radio v-model="item['style']['font-weight']" :label="'bold'">是</el-radio>
                          </div>
                        </div>
                        <div style="display: flex;align-items: center">
                          <div>文字颜色：</div>

                          <div>
                            <el-color-picker v-model="item['style']['color']"></el-color-picker>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="item['type']&&item['type']=='icon'">
                        <div style="display: flex;align-items: center">
                          <div>图标大小：</div>
                          <div>
                            <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'15px'">15px</el-radio>
                            <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'20px'">20px</el-radio>
                            <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'25px'">25px</el-radio>
                          </div>
                        </div>
                        <div style="display: flex;align-items: center">
                          <div>图标颜色：</div>
                          <div>
                            <el-color-picker v-model="item['style']['color']"></el-color-picker>
                          </div>
                        </div>
                      </div>


                      <div style="display: flex;align-items: center">
                        <div>上间距：</div>
                        <div>
                          <el-slider v-model="item['style']['margin-top']"  >
                          </el-slider>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>下间距：</div>
                        <div>
                          <el-slider v-model="item['style']['margin-bottom']"  >
                          </el-slider>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>左间距：</div>
                        <div>
                          <el-slider v-model="item['style']['margin-left']"  >
                          </el-slider>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>右间距：</div>
                        <div>
                          <el-slider v-model="item['style']['margin-right']">
                          </el-slider>
                        </div>
                      </div>



                    </div>

                  </el-tab-pane>
                  <!--                  <el-tab-pane label="配置管理">配置管理</el-tab-pane>
                                    <el-tab-pane label="角色管理">角色管理</el-tab-pane>
                                    <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane>-->
                </el-tabs>
                <div v-else>
                  <div v-for="(item,index) in scope.row.right"
                       style="border:1px solid #ededed;padding:10px;border-radius:4px;margin-bottom:10px;">

                    <div style="display: flex;align-items: center">
                      <div class="mr10">
                        设置内容:
                      </div>

                      <div :style="item.style">
                     <span v-if="item['type']&&item['type']=='icon'"
                           :class="'icon ' +item.value.substring(2,item.value.length-2)">
                     </span>
                        <span v-else>
                          {{ item.value }}
                      </span>

                      </div>

                    </div>

                    <div v-if="item['type']&&item['type']=='image'">
                      <div>图片宽</div>
                      <div>图片高</div>
                      <div>
                        <div>图片圆角：</div>
                        <el-slider v-model="item['style']['border-radius']" >
                        </el-slider>
                      </div>
                    </div>
                    <div v-else-if="item['type']&&(item['type']=='text'||item['type']=='priceF'||item['type']=='priceY')">
                      <div style="display: flex;align-items: center">
                        <div>文字大小：</div>
                        <div>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'12px'">12px</el-radio>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'14px'">14px</el-radio>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'16px'">16px</el-radio>
                        </div>
                      </div>

                      <div style="display: flex;align-items: center">
                        <div>是否加粗：</div>

                        <div>
                          <el-radio v-model="item['style']['font-weight']" :label="'normal'">否</el-radio>
                          <el-radio v-model="item['style']['font-weight']" :label="'bold'">是</el-radio>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>文字颜色：</div>

                        <div>
                          <el-color-picker v-model="item['style']['color']"></el-color-picker>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="item['type']&&item['type']=='icon'">
                      <div style="display: flex;align-items: center">
                        <div>图标大小：</div>
                        <div>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'15px'">15px</el-radio>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'20px'">20px</el-radio>
                          <el-radio v-model="item['style']['font-size']" style="margin-right:3px;" :label="'25px'">25px</el-radio>
                        </div>
                      </div>
                      <div style="display: flex;align-items: center">
                        <div>图标颜色：</div>
                        <div>
                          <el-color-picker v-model="item['style']['color']"></el-color-picker>
                        </div>
                      </div>
                    </div>

                    <div style="display: flex;align-items: center">
                      <div>上间距：</div>
                      <div>
                        <el-slider v-model="item['style']['margin-top']"  >
                        </el-slider>
                      </div>
                    </div>
                    <div style="display: flex;align-items: center">
                      <div>下间距：</div>
                      <div>
                        <el-slider v-model="item['style']['margin-bottom']"  >
                        </el-slider>
                      </div>
                    </div>
                    <div style="display: flex;align-items: center">
                      <div>左间距：</div>
                      <div>
                        <el-slider v-model="item['style']['margin-left']"  >
                        </el-slider>
                      </div>
                    </div>
                    <div style="display: flex;align-items: center">
                      <div>右间距：</div>
                      <div>

<!--                        <el-slider v-model="item['style']['margin-right']"   v-bind="mOptions">
                        </el-slider>-->

<!--                        <el-slider v-model="item['style']['margin-right']"></el-slider>-->

                        <el-slider ></el-slider>


                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <div v-else>
                --
              </div>
            </template>
          </el-table-column>


        </el-table>


        <div
            slot="footer"
            class="dialog-footer mt20" style="text-align: center"
        >
          <el-button type="primary" @click="btnSure"
          >确 定
          </el-button>
        </div>


      </div>

    </el-dialog>

  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import {getDecoratorListByRuleIds} from "../../../api/easyApp";

import {deepClone} from "@/js/utils";
import {mapGetters} from "vuex";

export default {
  name: "SchemaDecSource",
  mixins: [schemaMixin],
  props: {
    labelSwitch: {
      default: true,
    },
    isShow: {
      default: true,
    },
    tags: {
      type: String,
      default: "",
    },
    value: {
      default: () => ({}),
    },

  },

  inject: ['mode',"control"],

  computed: {
    ...mapGetters(["project", "curPage"])
  },
  data() {
    return {
      tagList: [],
      valueNew: '',
      show: false,
      defaultOptions: {
        max: 100,
      },
      pageStyle:[],
      isSupport:true
    };
  },

  /*  watch: {
      valueNew: {
        immediate: true,
        deep:true,
        handler(newVal,oldVal) {
          //this.value = this.valueNew;
        },
      },
    },*/

  async created() {
    //根据当前组件判断下是否支持装饰器
    let info=this.project.appComList.find((page) => page.componentSign == this.control.curWidget.component);
    if(info&&info.supportDecorator==1){
      let data = {
        ruleIds: this.tags
      }

      let res = await getDecoratorListByRuleIds(data);
      this.tagList = res.data;
    }
    else{
      this.isSupport=false;
    }



  },
  methods: {
    btnSure() {
      this.mValue.pageStyle=deepClone(this.pageStyle)
      this.show = false;
    },
    btnClose() {
      this.show = false;
    },
    switchPageStyle(e) {
      let index = this.tagList.findIndex(
          (item) => item.decoratorId == e
      );

      let mvalue = JSON.parse(this.tagList[index].config);

      for (var i in mvalue) {
        let row = mvalue[i];

        for (var j in row) {
          let cols = row[j];

          for (var h in cols) {
            if (cols[h]['type'] && cols[h]['type'] == 'image') {
              cols[h]['style'] = {
                width: '100px',
                height: '100px',
                "margin-top":0,
                "margin-left":0,
                "margin-right":0,
                "margin-bottom":0
              }


            } else if (cols[h]['type'] && cols[h]['type'] == 'icon') {
              cols[h]['style'] = {
                'font-size': '20px',
                'color': '#409fee',
                "margin-top":0,
                "margin-left":0,
                "margin-right":0,
                "margin-bottom":0
              }
            } else if (cols[h]['type'] && (cols[h]['type'] == 'text'||cols[h]['type'] == 'priceF'||cols[h]['type'] == 'priceY')) {
              cols[h]['style'] = {
                'font-size': '14px',
                'font-weight': 'normal',
                'color': '#333',
                "margin-top":0,
                "margin-left":0,
                "margin-right":0,
                "margin-bottom":0
              }
            }
          }

        }

      }

      this.mValue.pageStyle = mvalue;

      console.log("this.mValue:", this.mValue);


    },
    //弹出分组框
    btnShowGroup() {
      this.pageStyle=deepClone(this.mValue.pageStyle)
      this.show = true;
    },
  }


};
</script>

<style lang="scss" scoped>
@import "iconfontNew.css";

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 25px; /*no*/

  .wrap-label {
    font-size: 14px; /*no*/
    line-height: 32px; /*no*/
  }
}

.dContent {
  margin-top: 20px;
  display: flex;
  margin-left: -26%;
  align-items: center;
}

.dLi {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.dCol {
  border: 1px solid #ededed;
  width: 33.33%;
  padding: 15px;
  font-size: 12px;
  text-align: center;
}
/*
.right-aligned-dialog {
  margin-left: auto!important;
  margin-right: 0!important;

 !* ::v-deep .el-dialog {
    margin-left: auto;
    margin-right: 0;
  }*!

}*/

::v-deep .right-aligned-dialog {
  margin-left: auto!important;
  margin-right: 0!important;
}

</style>