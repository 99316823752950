<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-26 14:16:11
-->
<template>
  <div v-show="isShow">
    <div v-if="label">
      <config-item :label="label">
        <FaImageUpload v-model="mValue" v-bind="mOptions" :maxCount="1" />
      </config-item>
    </div>
    <div v-else>
      <FaImageUpload v-model="mValue" v-bind="mOptions" :maxCount="1" />
    </div>
    <div style="font-size: 12px;color: #969799;transform: translateX(26%);">
      {{mOptions.note}}
    </div>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

export default {
  name: "SchemaUpload",
  mixins: [schemaMixin],
  props: {
    isShow: {
      default: true,
    }

  },
};
</script>
