<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-08-12 17:23:08
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-08-31 20:12:08
-->
<template>



  <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" title="请选择跳转页面" width="37%">


    <div class="list mb30">
      <div v-for="(tab, i) in list" :key="i" :class="[value.type == tab.type ? 'tab-active' : '']" class="tab flex-center" @click="changeTab(tab.type)">
        {{ tab.label }}
      </div>
    </div>


    <!-- 微页面 -->
    <template>
      <el-select
          v-model="value.id"
          placeholder="请选择要跳转的微页面"
          size='mini'
          style="width:250px"
      >
        <el-option
            v-for="(page,i) in fixedPages"
            :key="i"
            :label="page.name"
            :value="page.id"
        >
        </el-option>
      </el-select>
    </template>


    <!-- 站内链接 -->
    <template v-if="value.type == 'insid'">
      <el-table ref="insidTable" :data="dataList" :row-key="getRowKey" v-loading="dataListLoading" class="insidTab" size="mini" stripe max-height="500">
        <el-table-column align="center" reserve-selection type="selection" width="55">
          <template slot-scope="scope">
            <input type="checkBox" :checked="value.id.includes(scope.row.pagesId)" @click="btnClickRow(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="页面名称" prop="name"></el-table-column>
        <el-table-column label="槽位" prop="sign"></el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.current"
        :page-size="queryForm.size"
        :pager-count="5"
        :page-sizes="[10, 20, 50, 100, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
        background
        class="pagina mt15">
      </el-pagination>
    </template>

    <!-- 站外链接 -->
    <template v-if="value.type == 'link'">
      <el-form ref="form" :model="value" label-width="80px">
        <el-form-item label="跳转方式" prop="mode">
          <el-radio-group v-model="value.mode">
            <el-radio label="1">H5跳转</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" prop="mode">
          <el-input v-model="value.id" placeholder="请输入h5跳转链接" size="mini" style="width:250px"></el-input>
        </el-form-item>
      </el-form>
    </template>

    <!-- 跳转小程序 -->
    <template v-if="value.type == 'applet'">
      <el-form ref="form" :model="value" label-width="80px">
        <el-form-item label="跳转方式:" prop="mode">
          <el-radio-group v-model="value.mode">
            <el-radio label="1">常规跳转</el-radio>
            <el-radio label="2">微信半屏小程序</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="App ID:" prop="mode">
          <el-input v-model="value.id" placeholder="请输入小程序APPID" size="mini" style="width:250px"></el-input>
        </el-form-item>
        <el-form-item label="路径:" prop="mode">
          <el-input v-model="value.data.jump" placeholder="请输入跳转链接" size="mini" style="width:250px"></el-input>
        </el-form-item>
      </el-form>
    </template>



  </el-dialog>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

import { getFormPage } from "@/api/project";

export default {
  name: "JumpDialog",
  inject: ["value"],
  data() {
    return {
      list: [
        { label: "底部导航", type: "fixed" },
        { label: "站内链接", type: "insid" },
        { label: "站外链接", type: "link" },
        { label: "跳转小程序", type: "applet" },
        { label: "不跳转", type: undefined },
      ],
      dataList: [],
      totalPage: 0,
      dialogVisible: false,
      dataListLoading: false,
      queryForm: {
        aid: '',
        size: 10,
        current: 1,
      },
    };
  },
  computed: {
    ...mapGetters(["project", "fixedPages"]),
    customPages() {
      return this.project.pagesEntityList;
    },
  },
  created() {

  },
  methods: {
    // 开启
    open() {
      this.dialogVisible = true;
      this.getDataList();
    },

    // 获取table 列表
    getDataList() {
      this.queryForm.aid = this.project.aid;
      getFormPage(this.queryForm).then(res => {
        this.dataList = res.data.records;
        this.totalPage = res.data.total;
      }).catch((err) => {
        this.$message.error(err.msg);
      });
    },

    // 行数据的 Key
    getRowKey(row) {
      return row.pagesId;
    },

    // 选中
    btnClickRow(row) {
      let index = this.value.id.indexOf(row.pagesId);
      if (index !== -1) {
        this.value.data = {};
        this.value.id = "";
      } else {
        this.value.data = row;
        this.value.id = row.pagesId;
      }
    },

    // 请选择跳转页面
    changeTab(type) {
      if (this.value.type == type) return;

      this.$set(this.value, "type", type);
      this.$set(this.value, "id", "");
      this.$set(this.value, "data", {});

      if (type == 'link' || type == 'applet') {
        this.$set(this.value, "mode", "1");
      } else {
        this.$set(this.value, "mode", "");
      }

      if (type == 'insid') {
        this.getDataList();
      }
    },

    // 每页数
    handleSizeChange(val) {
      this.queryForm.size = val;
      this.queryForm.current = 1;
      this.getDataList();
    },

    // 当前页
    handleCurrentChange(val) {
      this.queryForm.current = val;
      this.getDataList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/variable";

.list {
  display: flex;

  .tab {
    width: 100px;
    height: 30px;
    color: #979797;
    border: solid 1px #ebedf0;
    cursor: pointer;
  }

  .tab-active {
    color: $color-theme;
    border: solid 1px $color-theme;
    background: #e0edff;
  }
}

.first-leve {
  width: 100%;
  height: 33px;
  line-height: 33px;
  padding: 0 20px;
  cursor: pointer;
}

.active {
  color: #ffffff;
  background: rgb(0, 106, 255);
}

.active-Scon {
  color: rgb(56, 148, 255);
  background: rgb(239, 250, 255);
}

::v-deep .insidTab .el-table__header-wrapper .el-table__header .el-checkbox {
  // 找到表头那一行，然后把里面的复选框隐藏掉
  display: none !important;
}

.el-cascader-panel {
  width: 140px !important;
  border: none !important;
}

.el-cascader-menu__list {
  width: 140px !important;
  overflow: hidden;
}

::v-deep .el-cascader-menu {
  min-width: 140px !important;
}
</style>
