import Vue from 'vue';
import global from '@/config/global';
import { getDictItem } from "@/api/enrollStudent";

export default async (file) => new Promise((resolve, reject) => {
  getDictItem('cdnAndHttp').then(res => {
    const value = res.data[0].value;

    Vue.prototype.$POST.upload(global.baseApi + 'admin/sys-file/upload', ({
      file, dir: 'person'
    }), {
      baseURL: '',
      timeout: 20000,
    }).then(res => {
      resolve(value + res.data.cdnUrl)
    }).catch(e => {
      reject(e)
    })
  })

})