<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-26 20:35:00
-->
<template>
  <config-item v-show="isShow" :label='label'>
    <config-color-picker v-model="mValue" :mOptions="mOptions"></config-color-picker>
  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

import ConfigColorPicker from "./ConfigColorPicker.vue";

export default {
  name: "SchemaColor",

  mixins: [schemaMixin],

  components: {
    ConfigColorPicker,
  },

  props: {
    value: {
      default: 0,
    },
    labelSwitch: {
      default: true,
    },
    isShow: {
      default: true,
    },
    data: {
      type: Array,
    }
  },

};
</script>