<!--
 * @Description: 模板单项组件
 * @Autor: WangYuan
 * @Date: 2021-05-31 11:55:13
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-01-27 19:43:23
-->
<template>
  <div :class="{ active: mode.mValue[1] == value }" class="select-viewMore  mt5" @click="bindChange(value)">
    <div :style="[viewMoreStyle(value)]">
      {{ value != 'four' ? '查看更多' : '' }}
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'select-see-more',

  props: ['label', 'value', 'icon'],

  inject: ['mode'],

  methods: {
    bindChange(val) {
      if (this.value[1] == val) return;
      this.mode.mValue = [this.mode.mValue[0], val];
      this.$forceUpdate();
    },

    viewMoreStyle(type) {

      let result = {
        fontSize: '12px',
        textAlign: 'center',
        borderRadius: '5px',
        padding: `5px 10px`,
        color: '#999'
      }
      if (type == 'one' || type == 'two') {
        result.border = '1px solid #eee'
        if (type == 'one') {
          result.borderRadius = '5px'
        } else {
          result.borderRadius = '30px'
        }
      } else {
        result.border = '1px solid transparent'
      }

      return result
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variable";

.select-viewMore-one {
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 5px 7px;
}

.select-viewMore-two {
  font-size: 12px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid #eee;
  padding: 5px 7px;
}

.select-viewMore-three {
  border-radius: 15px;
  font-size: 12px;
  text-align: center;
  padding: 5px 7px;
}

.select-viewMore-four {
  border-radius: 15px;
  font-size: 12px;
  text-align: center;
  padding: 5px 7px;
}
</style>
