<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-06-01 08:57:21
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-26 15:18:52
-->
<template>
  <config-item v-show="isShow" :label='label'>

    <div>

      <el-select style="width: 100%" v-model="mValue" size="small">
        <el-option
            v-for="item in tagList"
            :label="item.name"
            :value="item.url+'?tag='+item.tag"
        />
      </el-select>


    </div>

  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import {comDSList} from "../../../api/easyApp";

export default {
  name: "SchemaTagSource",
  mixins: [schemaMixin],
  props: {
    labelSwitch: {
      default: true,
    },
    isShow: {
      default: true,
    },
    tagCategory: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    }
  },

  inject: ['mode'],

  data() {
    return {
      defaultOptions: {
        trueLabel: "显示",
        falseLabel: "隐藏",
      },
      tagList:[],
      valueNew:'',

    };
  },

/*  watch: {
    valueNew: {
      immediate: true,
      deep:true,
      handler(newVal,oldVal) {
        //this.value = this.valueNew;
      },
    },
  },*/

  async created() {
    let data={
      tags:this.tagCategory
    }

    let res=await comDSList(data);
    this.tagList=res.data;

  }


};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 25px; /*no*/

  .wrap-label {
    font-size: 14px; /*no*/
    line-height: 32px; /*no*/
  }
}
</style>