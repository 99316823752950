<!--
 * @Description: 模板选择容器组件
 * @Autor: WangYuan
 * @Date: 2021-05-31 11:54:32
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-10-18 20:03:21
-->
<template>
  <div v-show="isShow">
    <div :class="[direction == 'column' ? 'flex-column' : '']" class="select">
      <ul :class="[direction == 'column' ? 'mb15' : '']" class="select-label">
        <li class="select-label-text">{{ label }}</li>
      </ul>

      <div class="select-body">
        <select-item v-for="(item, index) in data" :key="item.id || index" v-bind="item"
          @custom-event="receiveFromChild"></select-item>
      </div>
    </div>

    <div v-if="value[0] == 'true'" class="select-seeMore">
      <select-see-more v-for="(item, index) in list" :key="index" v-bind="item"></select-see-more>
    </div>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import SelectItem from "./SelectItem";
import SelectSeeMore from "./SelectSeeMore";

export default {
  name: "SchemaViewMore",

  components: {
    SelectItem,
    SelectSeeMore,
  },

  mixins: [schemaMixin],

  provide() {
    return {
      mode: this,
    };
  },

  data() {
    return {
      list: [
        { value: 'one' },
        { value: 'two' },
        { value: 'three' },
        { value: 'four' },
      ]
    }
  },

  inject: ['mode'],

  props: {
    value: {
      type: Array,
    },
    data: {
      type: Array,
    },
    isShow: {
      default: true,
    },
  },

  computed: {
    direction() {
      return this.data.length > 3 ? "column" : "row";
    },
  },

  methods: {
    receiveFromChild(data) {
      console.log("===data====:", data);
      data.ctrl?.show?.forEach(ele => {
        this.mode.schema[ele].isShow = true;
        this.$forceUpdate();
      });

      data.ctrl?.hide?.forEach(ele => {
        this.mode.schema[ele].isShow = false;
        this.$forceUpdate();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/variable";

.select {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  background: #fff;

  .select-label {
    display: flex;
    align-items: center;

    .select-label-text {
      width: 70px;
      color: #969799;
      font-size: 13px;
    }
  }

  .select-body {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
  }
}

.select-seeMore {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.select-viewMore {
  border: 1px solid #eee;
  width: 32%;
  border-radius: 3px;
  padding: 8px;
}

.active {
  background: #edf4ff;
  color: #409eff;
  border: 1px solid;
}

.mode-select {
  display: flex;
  justify-content: space-between;

  .mode-select-label {
    font-size: 14px;
    /*no*/
    line-height: 32px;
    /*no*/
  }

  .mode-select-body {
    display: flex;
    flex-wrap: wrap;
  }
}

.mode-select-item {
  padding: 8px 12px;
  min-width: 55px;
  border: 1px solid #dcdee0;
  /*no*/
  border-radius: 0;
  border-color: #ebedf0;
  font-size: 12px;
  /*no*/
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: $color-theme;
    border-color: $color-theme;
    background-color: #e0edff;

    i {
      color: $color-theme;
    }
  }

  i {
    color: #979797;
  }
}

.mode-select-item-active {
  color: $color-theme;
  border-color: $color-theme;
  background-color: #e0edff;

  i {
    color: $color-theme;
  }
}
</style>