<template>
    <el-row :gutter="10" class="mr0" style="margin-bottom:19px;">

        <el-col :span="1.5" style="margin-bottom:1px">
            <slot name="add"></slot>
        </el-col>
        <el-col :span="1.5" style="margin-bottom:1px">
            <slot name="update"></slot>
        </el-col>
        <el-col :span="1.5" style="margin-bottom:1px">
            <slot name="delete"></slot>
        </el-col>
        <el-col :span="1.5" style="margin-bottom:1px">
            <slot name="export"></slot>
        </el-col>

        <el-col :span="10" style="margin-bottom:1px">
            <slot name="group"></slot>
        </el-col>

        <el-button-group class="toolbar-right">
            <el-tooltip effect="dark" :content="showSearch ? '搜索' : '搜索'" placement="top" v-show="cate === ''">
                <el-button size="small" @click="toggleSearch" icon="el-icon-search"></el-button>
            </el-tooltip>

            <el-tooltip effect="dark" content="刷新" placement="top">
                <el-button size="small" @click="refresh" icon="el-icon-refresh"></el-button>
            </el-tooltip>
        </el-button-group>

    </el-row>
</template>

<script>
export default {
    name: "Toolbar",
    props: {
        showSearch: Boolean,
        cate: {
            type: String,
            default: ''
        }
    },
    methods: {
        // 搜索
        toggleSearch() {
            this.$emit('update:showSearch', !this.showSearch)
        },

        // 刷新
        refresh() {
            this.$emit("refreshTable");
        }
    }
}
</script>

<style scoped>
.toolbar-right {
    float: right;
}
</style>
