<template>
    <el-drawer v-if="showable" :visible.sync="showable" direction="rtl" size="80%" :withHeader="false"
        :append-to-body="appendToBody" :modal="false" :destroy-on-close="true" :before-close="handleDrawerClose">

        <slot></slot>
    </el-drawer>
</template>
<script>
export default {
    props: {
        size: "",
        visible: {
            type: Boolean,
            default: false,
        },
        appendToBody: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            show: false,
            width: 0,
        };
    },
    created() {
        this.computedClosePos();
    },
    mounted() { },
    computed: {
        showable() {
            return this.show || this.visible;
        },
    },
    methods: {
        computedClosePos() {
            try {
                let right = (window.innerWidth * this.size.substr(0, 2)) / 100 + 50;
                this.width = right;
                document.querySelector(".el-drawer").style.minWidth = right + "px";
            } catch (error) { }
        },
        handleDrawerClose() {

            this.show = false;


            this.$emit("close");
            this.$emit("update:visible", false);
        },
    },
};
</script>